import React, {Component} from 'react';
import { withRouter} from 'react-router-dom';

class Index extends Component{

  componentDidMount(){
    return this.props.history.replace('/login');
  }

  render(){
    return(
        <div>

        </div>
    );
  }
}

export default withRouter(Index);
