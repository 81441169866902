import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import api from '../../../api';

class Logout extends Component{

    componentDidMount()
    {
        api.logout();
        window.location = '/login';
    }

    render(){
        return(
            <div>

            </div>
        );
    }
}

 export default withRouter(Logout);