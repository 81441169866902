import React from 'react';
import { Link } from 'react-router-dom';

function CardMenu(){
    return(
        <div className='row' id='div-card-menu'>
            <div className="col-sm-6 col-12">
                <div className="card">
                    <Link to="/dashboard">
                        <div className="card-header d-flex align-items-start pb-1">
                            <div>
                                <h2 className="text-bold-700">Meus álbuns</h2>
                            </div>
                            <div className="avatar bg-rgba-primary p-50 m-0">
                                <div className="avatar-content">
                                    <i className="feather icon-shopping-cart text-primary font-medium-5"></i>
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>
            </div>
            <div className="col-sm-6 col-12">                
                <div className="card">
                    <Link to="/profile">
                        <div className="card-header d-flex align-items-start pb-1">
                            <div>
                                <h2 className="text-bold-700">Meu perfil</h2>
                            </div>
                            <div className="avatar bg-rgba-primary p-50 m-0">
                                <div className="avatar-content">
                                    <i className="feather icon-user text-primary font-medium-5"></i>
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>
            </div>
        
        </div>
    );
}

export default CardMenu;