import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';

class Error401 extends Component{

    render(){
        return(
            <div>
                <div className="app-content content">
                    <div className="content-overlay" />
                    <div className="header-navbar-shadow" />
                    <div className="content-wrapper">
                    <div className="content-header row">
                    </div>
                    <div className="content-body">
                        {/* error 401 */}
                        <section className="row mt-4">
                        <div className="col-12 d-flex justify-content-center">
                            <div className="card bg-transparent shadow-none rounded-0 mb-0 w-100">
                            <div className="card-content">
                                <div className="card-body text-center">
                                <h1 className="font-large-2 text-white my-1">Faça o login para continuar</h1>
                
                                <Link className="btn btn-primary btn-lg mt-2" to="/login">Ir para o login</Link>
                                </div>
                            </div>
                            </div>
                        </div>
                        </section>
                        {/* error 401 end */}
                    </div>
                    </div>
                </div>
            </div>
        );
    }
    
}

export default withRouter(Error401);