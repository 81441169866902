import api from "../api";

class Artist {

    //URL base para requisições na API
    baseUrl = process.env.REACT_APP_API_URL


    //busca de artistas
    getArtists = async(name) => {
        let success  = true;
        let log      = '';
        let artists  = [];
        let url      = this.baseUrl+`/artists/remote/`+name;

        await fetch(url, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+api.getConnectUuid()
            },          
            method: 'GET'
        })
        .then((r) => r.json())
        .then((json) => {
 
            success = true;
            log = '';
            artists = json;

        }).catch(error => {

            success = false;
            log = 'Internal Server Error';

        });

        return {
            success: success,
            log: log,
            artists: artists
        };
    }

    
    //cadastro de artista
    createArtist = async(name, primary, productId, assetId) => {
        let success  = true;
        let log      = '';
        let url      = this.baseUrl+`/artists`;

        await fetch(url, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+api.getConnectUuid()
            },          
            method: 'POST',
            body: JSON.stringify({name: name, primary: primary, product_id: productId, asset_id: assetId})
        })
        .then((r) => r.json())
        .then((json) => {
 
            success = json.success;
            log = json.log;

        }).catch(error => {

            success = false;
            log = 'Internal Server Error';

        });

        return {
            success: success,
            log: log
        };
    }
    

    //exclui artista do álbum informado
    deleteArtistFromProduct = async(artistId, productId) => {
        let success  = true;
        let log      = '';
        let url      = this.baseUrl+`/artists/`+artistId+`/product/`+productId;

        await fetch(url, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+api.getConnectUuid()
            },          
            method: 'DELETE'
        })
        .then((r) => r.json())
        .then((json) => {
 
            success = json.success;
            log = json.log;

        }).catch(error => {

            success = false;
            log = 'Internal Server Error';

        });

        return {
            success: success,
            log: log
        };
    }

    //exclui artista da música informada
    deleteArtistFromAsset = async(artistId, assetId) => {
        let success  = true;
        let log      = '';
        let url      = this.baseUrl+`/artists/`+artistId+`/asset/`+assetId;

        await fetch(url, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+api.getConnectUuid()
            },          
            method: 'DELETE'
        })
        .then((r) => r.json())
        .then((json) => {
 
            success = json.success;
            log = json.log;

        }).catch(error => {

            success = false;
            log = 'Internal Server Error';

        });

        return {
            success: success,
            log: log
        };
    }
}

export default new Artist();