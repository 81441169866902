import React from 'react';
import ReactLoading from 'react-loading';
import './style.css';

const Loading = ({ type, color }) => (
    <div id='react-loading' >
        <ReactLoading id='loader' type={type} color={color} height={'4vw'} width={'4vw'} />
    </div>
);
 
export default Loading;