import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import api from '../../../api';
import Modal from '../../plugins/Modal';
import Autosuggest from 'react-autosuggest';
import { toast } from 'react-toastify';
import Select from 'react-select';


class Contributors extends Component {

    constructor(props){
        super(props);
        this.state = {
            modal: false,
            apiUrl: process.env.REACT_APP_API_URL,
            value: '',
            product: props.state.product,
            asset: props.state.asset,
            suggestions: [],
            contributors: props.state.contributors,
            contributorRoles: [],
            contributorRoleSelected: '',
        };

        this.openModal           = this.openModal.bind(this);
        this.closeModal          = this.closeModal.bind(this);
        this.changeRole          = this.changeRole.bind(this);
        this.confirmContributor  = this.confirmContributor.bind(this);
        this.getSuggestions      = this.getSuggestions.bind(this);
        this.getSuggestionvalue  = this.getSuggestionValue.bind(this);
        this.renderSuggestion    = this.renderSuggestion.bind(this);
        this.deleteContributor   = this.deleteContributor.bind(this);
    }


    componentDidMount()
    {
        this.getContributorRoles();
    }


    /* busca as funções de colaboradores */
    getContributorRoles = async() => {

        try 
        {
            var result = await api.getContributorRoles();

            if(!result.success)
            {
                toast.error(result.log);

                return null;
            }
            else 
            {
                let roles = [];

                for (let key in result.roles) {
                    let value  = result.roles[key];
                    let option = {
                        "value":value.id,"label":value.name
                    }
                    roles.push(option);
                }                
                this.setState({contributorRoles: roles});
            }
        }
        catch(err)
        {
            toast.error(err.message);
        }
    }


    /* abre a modal de cadastro de colaborador */
    openModal(){
        this.setState({modal: true});
    }


    /* fecha a modal de cadastro de colaborador */
    closeModal(){
        this.setState({modal: false});
    }


    /* atualiza o valor da variável */
    onChange = (event, { newValue }) => {
        this.setState({
            value: newValue
        });
    };


    /* atualiza a função do colaborador */
    changeRole = selectedOption =>
    {
        this.setState({contributorRoleSelected: selectedOption.value});
    }


    /* atribui o colaborador a musica no webservice */
    confirmContributor = async(e) => {
        e.preventDefault();
        const {value, contributorRoleSelected} = this.state;
        try 
        {
            var result = await api.createContributor(value, contributorRoleSelected, this.state.asset.id);

            if(!result.success)
            {
                toast.error(result.log);

                return null;
            }
            else 
            {

                toast.success(result.log);
                
                this.setState({modal: false});

                this.props.loadAsset();
            }
        }
        catch(err)
        {
            toast.error(err.message);
        }
    }
    
    /* atribui a variável, as sugestões encontradas com getSuggestions */
    onSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            suggestions: this.getSuggestions(value)
        });
    };

    /* limpa a variável de sugestões */
    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };

    /* busca as sugestões de acordo com o valor preenchido */
    getSuggestions = value => {
        const inputValue  = value.trim();
        const inputLength = inputValue.length;

        if(inputLength === 0)
        {
            return [];
        }
        else 
        {
            const {contributors} = this.state;

            let items = [];

            for (let key in contributors) {
                let value  = contributors[key];
                let option = {
                    "value":value.value,"label":value.label
                }
                items.push(option);
            }
            
            return items.length === 0 ? [] : items.filter(item =>
                item.label.slice(0, inputLength) === inputValue
            );
         
        }
    };
  
    /* preenche o input ao clicar na sugestão exibida */
    getSuggestionValue = suggestion => suggestion.label;
  
    /* renderiza as sugestões */
    renderSuggestion = suggestion => (
        <div>
            {suggestion.label}
        </div>
    );

    /* exclui o colaborador da música */
    deleteContributor = async(e, contributorId) => {
        e.preventDefault();
        const {asset} = this.state;
        try 
        {
            var result = await api.deleteContributorFromAsset(contributorId, asset.id);

            if(!result.success)
            {
                toast.error(result.log);

                return null;
            }
            else 
            {
                toast.success(result.log);

                this.props.loadAsset();
            }
        }
        catch(err)
        {
            toast.error(err.message);
        }
    }

    render() {

        return (
            
            <div className="tab-pane" id="tab-container-contributors" role="tabpanel" aria-labelledby="tab-option-contributors" aria-expanded="false">
                <section>
                    <div className="row row-no-margin">
                    
                        <div className="col-xl-3 col-sm-3 col-sm-12 pt-1">
                            <div className="card-people-add" onClick={this.openModal}>
                                <div className="card-people-add-icon">
                                    <i className="feather icon-user-plus"></i>
                                </div>
                            </div>
                        
                        </div>

                        {this.props.state.asset.contributors.map((item) => {
                            return (
                                <div className="col-xl-3 col-sm-3 col-sm-12 pt-1" key={item.id}>
                                    <div className='bg-white p-1 card-people-item'>
                                        <h5 className="my-1 font-weight-700">{item.person.name}</h5>
                                        <small className="mb-2">{item.role.name}</small>
                                        <div className="card-people-item-footer">
                                            <button onClick={(e) => {this.deleteContributor(e, item.person.id)}} className="btn btn-sm btn-block btn-outline-primary">
                                                <i className="feather icon-trash"></i> Excluir
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}

                    </div>
                </section>

                <Modal
                    class="modal-wrapper"
                    show={this.state.modal}
                    close={this.closeModal}
                    title='Adicionar colaborador'>
                        <div className="modal-body">
                            <h6>Nome do colaborador: </h6>
                            <div className="form-group">
                                <Autosuggest
                                    className="form-control"
                                    suggestions={this.state.suggestions}
                                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                    onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                    getSuggestionValue={this.getSuggestionValue}
                                    renderSuggestion={this.renderSuggestion}
                                    inputProps={{
                                        placeholder: 'Digite o nome do colaborador',
                                        value: this.state.value,
                                        onChange: this.onChange,
                                        className: "form-control"
                                    }}
                                />
                            </div>

                            <div>
                                <h6>Função do colaborador: </h6>
                                <Select
                                    placeholder="Selecione..."
                                    id="role" 
                                    defaultValue={this.state.contributorRoleSelected}
                                    onChange={this.changeRole}
                                    options={this.state.contributorRoles}
                                />
                            </div>
                        </div>
                        <div className="modal-footer mt-2">
                            <button type="button" className="btn btn-primary" onClick={(e) => {this.confirmContributor(e)}}>Confirmar</button>
                        </div>
                </Modal>
            </div>
        
        )
    }
}

export default withRouter(Contributors);
