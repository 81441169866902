import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import Modal from '../../plugins/Modal';
import { toast } from 'react-toastify';
import api from '../../../api';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Select from 'react-select';


class Asset extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            modalUpload: false,
            modalAdd: false,
            apiUrl: process.env.REACT_APP_API_URL,
            btnUploadDisabled: false,
            btnAddDisabled: false,
            files: [], 
            assets: props.state.product.assets,
            existingAssets: [], //para adicionar ao álbum
            existingAssetSelected: '',
        };

        this.loadAllAssets = this.loadAllAssets.bind(this);
        this.openModal   = this.openModal.bind(this);
        this.closeModal  = this.closeModal.bind(this);
        this.triggerAssetUploadClick = this.triggerAssetUploadClick.bind(this);
        this.selectFiles = this.selectFiles.bind(this); 
        this.removeFile  = this.removeFile.bind(this);
        this.changeUploadValue  = this.changeUploadValue.bind(this);
        this.confirmAssetUpload = this.confirmAssetUpload.bind(this);
        this.onDragEnd = this.onDragEnd.bind(this);
        this.reorder   = this.reorder.bind(this);
        this.deleteAssetFromProduct = this.deleteAssetFromProduct.bind(this);
        this.changeExistingAsset = this.changeExistingAsset.bind(this);
        this.confirmAssetAdd = this.confirmAssetAdd.bind(this);
    }

    componentDidMount(){
        var event = document.createEvent('Event');
        this.loadAllAssets(event);
    }


    /* busca todas as músicas cadastradas para adicionar ao álbum */
    loadAllAssets = async(e) => {
        e.preventDefault();
        try 
        {
            var result = await api.getAssets();

            let assets = [];

            for (let i = 0; i < result.assets.length; i++) {
                let asset = result.assets[i];
                let option = {
                    "value":asset.id,"label":asset.name + " - ISRC: "+asset.isrc
                }

                assets.push(option);
            }

            this.setState({existingAssets: assets});
        }
        catch(err)
        {
            //
        }
    }


    /* gatilho drag and drop para reordenar assets*/
    onDragEnd = async(result) =>  {

        // dropped outside the list
        if (!result.destination) {
          return;
        }
    
        var assets = this.reorder(
            this.props.state.product.assets,
            result.source.index,
            result.destination.index
        );

        this.setState({assets: assets});

        var event = document.createEvent('Event');

        this.sortAssets(event,assets);

        
    }

    /* gera array com nova ordem de assets */
    reorder(list, startIndex, endIndex) {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
      
        return result;
    };


    /* reordena lista de assets na API */
    sortAssets = async(e, assets) => {
        e.preventDefault();

        try 
        {
            var result = await api.sortAssets(this.props.state.product.id, assets);

            if(!result.success)
            {
                toast.error(result.log);

                return null;
            }
            else 
            {
                toast.success(result.log);
            }
        }
        catch(err)
        {
            toast.error(err.message);
        }

    }
    

    /* abre a modal de upload de músicas */
    openModal(type){
        if(type === 'upload')
        {
            this.setState({modalUpload: true});
        }
        else 
        {
            this.setState({modalAdd: true});
        }
    }


    /* fecha a modal de upload de músicas */
    closeModal(type){
        if(type === 'upload')
        {
            this.setState({modalUpload: false});
        }
        else 
        {
            this.setState({modalAdd: false});
        }
    }


    /* dispara click no botão de upload */
    triggerAssetUploadClick()
    {
        this.assetUploadElement.click();
    }


    /* recebe os arquivos selecionados para montar o formulário */
    selectFiles(fileItems)
    {
        const {files} = this.state;

        for (let i = 0; i < fileItems.length; i++) {
            var extension = fileItems[i].name.split('.').pop();

            if(extension.toLowerCase() !== 'wav')
            {
                toast.error('O arquivo '+fileItems[i].name+' possui formato inválido.');
            }
            else 
            {
                var item = {
                    ref: 'file-'+((+new Date()) + Math.random()* 100).toString(32),
                    name: fileItems[i].name,
                    isrc: '',
                    filename: fileItems[i].name,
                    file: fileItems[i],
                }

                files.push(item);
            }
        }

        this.setState({files: files});
    }


    /* exclui o arquivo do array que será enviado no post */
    removeFile(ref)
    {
        let items = [];
        const {files} = this.state;

        for (let i = 0; i < files.length; i++) {
            if(ref !== files[i].ref)
            {
                items.push(files[i]);
            }
        }

        this.setState({files: items});
    }


    /* atualiza os dados do arquivo a ser enviado */
    changeUploadValue(ref, key, value)
    {
        let items = [];
        const {files} = this.state;

        for (let i = 0; i < files.length; i++) {
            if(ref !== files[i].ref)
            {
                items.push(files[i]);
            }
            else 
            {
                if(key === 'name')
                {
                    files[i].name = value;
                }
                else 
                {
                    files[i].isrc = value;
                }

                items.push(files[i]);
            }
        }

        this.setState({files: items});
    }


    /* realiza o upload das músicas */
    confirmAssetUpload = async(e) => {
        e.preventDefault();
        const {files} = this.state;
        this.setState({btnUploadDisabled:true});
        try 
        {
            if(files.length === 0)
            {
                toast.error("Nenhum arquivo para ser enviado");
                this.setState({btnUploadDisabled:false});

            }
            else 
            {
                var success = true;
                var log     = '';

                //realizamos um foreach para verificar se as informações estão completas
                for (let i = 0; i < files.length; i++) {
                    if(files[i].name === '' || files[i].name === null)
                    {
                        success = false;
                        log = 'Verifique se todas as músicas tiveram os nomes informados'
                    }
                }

                if(!success)
                {
                    toast.error(log);
                    this.setState({btnUploadDisabled:false});
                }
                else 
                {
                    for (let i = 0; i < files.length; i++) {
                        
                        var result = await api.createAsset(files[i].name, files[i].isrc, files[i].file, this.props.state.product.id);


                        if(!result.success)
                        {
                            toast.error(result.log);
                        }
                        else 
                        {

                            toast.success('Música '+files[i].name+' enviada com sucesso');

                            //exclui o arquivo da modal de uploads
                            this.removeFile(files[i].ref);
                            
                            this.props.loadProduct();

                            this.setState({assets: this.props.state.product.assets});

                            this.loadAllAssets();

                        }
                    }

                    this.setState({btnUploadDisabled:false});

                    this.closeModal('upload');
                }
            }
        
        }
        catch(err)
        {
            toast.error(err.message);
        }
    }


    /* exclui música do álbum*/
    deleteAssetFromProduct = async(e, assetId) => {
        e.preventDefault();
        try 
        {
            var result = await api.deleteAssetFromProduct(assetId, this.props.state.product.id);

            if(!result.success)
            {
                toast.error(result.log);

                return null;
            }
            else 
            {
                toast.success(result.log);

                this.props.loadProduct();

                this.setState({assets: this.props.state.product.assets});
            }
        }
        catch(err)
        {
            toast.error(err.message);
        }
    }

    /* atualiza o asset a ser adicionado */
    changeExistingAsset = selectedOption =>
    {
        this.setState({existingAssetSelected: selectedOption.value});
    }


    /* adiciona música previamente cadastrada ao álbum */
    confirmAssetAdd = async(e) => {
        e.preventDefault();
        const {existingAssetSelected} = this.state;
        this.setState({btnAddDisabled:true});

        try 
        {
            var result = await api.addAssetToProduct(existingAssetSelected, this.props.state.product.id);

            if(!result.success)
            {
                this.setState({btnAddDisabled:false});

                toast.error(result.log);

                return null;
            }
            else 
            {
                this.setState({btnAddDisabled:false});

                toast.success(result.log);

                this.props.loadProduct();

                this.setState({assets: this.props.state.product.assets});

                this.setState({modalAdd:false});
            }
        }
        catch(err)
        {
            this.setState({btnAddDisabled:false});
            toast.error(err.message);
        }
    }


    render() {

        return (
            <div role="tabpanel" className="tab-pane active" id="tab-container-assets" aria-labelledby="tab-option-assets" aria-expanded="false">
                <div className="row match-height">
                    <div className="col-12">

                        <div className="col-12 product-tab-subtitle">
                            Músicas do álbum 
                            <button className="btn btn-sm btn-primary pull-right" onClick={(e) => {this.openModal('upload')}}>
                                <i className="ficon feather icon-upload"></i> Enviar
                            </button>
    
                            <button className="btn btn-sm btn-white text-primary mr-1 pull-right" onClick={(e) => this.openModal('add')}>
                                <i className="ficon feather icon-upload"></i> Adicionar existente
                            </button>
                        </div>

                        <div className="row mt-1">

                            <div className="col-12">
                                <div className="row row-no-margin border-radius-5" style={{padding: 12,background: "#ffe6f8", marginBottom: '5px'}}>
                                    <div className="col-1 text-center">
                                        <strong>#</strong>
                                    </div>
                                    <div className="col-4">
                                        <strong>Nome</strong>
                                    </div>
                                    <div className="col-3">
                                        <strong>Artista</strong>
                                    </div>
                                    <div className="col-2">
                                        <strong>ISRC</strong>
                                    </div>
                                    <div className="col-2 text-center">
                                        <strong>Excluir</strong>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12" style={{padding: '0 10px 0 10px'}}>
                            <DragDropContext onDragEnd={ (e) => this.onDragEnd(e)}>
                                <Droppable droppableId="droppable">
                                {(provided, snapshot) => (
                                    <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    style={{
                                        background: "transparent",
                                        padding: 8,
                                        width: "100%"
                                    }}
                                    >
                                    {this.state.assets.map((item, index) => (
                                        <Draggable key={item.id} draggableId={'key-'+item.id} index={index}>
                                            {(provided, snapshot) => (
                                                <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={{
                                                    userSelect: "none",
                                                    padding: 12,
                                                    margin: `0 0 8px 0`,
                                                    borderRadius: '5px',
                                                    background: snapshot.isDragging ? "#efe6f8" : "#f2f2f2",
                                                    ...provided.draggableProps.style
                                                }}
                                            >
                                                <div className="row">
                                                    <div className="col-1 text-center">
                                                        {index + 1}
                                                    </div>
                                                    <div className="col-4">
                                                        <Link to={"/products/"+this.props.state.product.id+"/asset/"+item.id}>{item.name}</Link>
                                                    </div>
                                                    <div className="col-3">
                                                        {item.display_artist ? item.display_artist : '-'}
                                                    </div>
                                                    <div className="col-2">
                                                        <small>{item.isrc}</small>
                                                    </div>
                                                    <div className="col-2 text-center">
                                                        <i className="feather icon-trash text-primary cursor-pointer" onClick={(e) => {this.deleteAssetFromProduct(e, item.id)}}></i>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                    </div>
                                )}
                                </Droppable>
                            </DragDropContext>
                            </div>
                        </div>
                    </div>
                </div>

                <Modal
                    className="modal modal-lg"
                    show={this.state.modalUpload}
                    close={(e) => {this.closeModal('upload')}}
                    title='Enviar músicas'>

                        <input type="file" id="asset-upload" multiple hidden ref={input => this.assetUploadElement = input}
                            onChange={ (e) => this.selectFiles(e.target.files)} />

                        <div className="modal-body">
                            
                            <div className="row row-no-margin">
                                <div className="col-4">
                                    Nome da música
                                </div>
                                <div className="col-3">
                                    ISRC
                                </div>
                                <div className="col-3">
                                    Arquivo
                                </div>
                                <div className="col-2">
                                    <button disabled={this.state.btnUploadDisabled} className="btn btn-white text-primary cursor-pointer" onClick={(e) => {this.triggerAssetUploadClick()}}>
                                        <i className="feather icon-plus"></i> ADD
                                    </button>
                                </div>
                            </div>
                            <hr />

                            {this.state.files.map((item) => {
                                return (
                                    <div className="row row-no-margin mb-1" key={item.ref}>
                                        <div className="col-4">
                                            <div className="form-group">
                                                <input type="text" className="form-control" value={item.name}
                                                 onChange={(e) => {this.changeUploadValue(item.ref, 'name', e.target.value)}}/>
                                            </div>
                                        </div>
                                        <div className="col-3">
                                            <div className="form-group">
                                                <input type="text" className="form-control" value={item.isrc}
                                                 onChange={(e) => {this.changeUploadValue(item.ref, 'isrc',e.target.value)}}/>
                                            </div>
                                        </div>
                                        <div className="col-3">
                                            {item.filename}
                                        </div>
                                        <div className="col-2">
                                            <button  disabled={this.state.btnUploadDisabled} className="btn btn-white text-primary cursor-pointer" onClick={(e) => {this.removeFile(item.ref)}}>
                                                <i className="feather icon-trash"></i>
                                            </button>
                                        </div>
                                        <hr />
                                    </div>
                                );
                            })}
                        </div>
                        <div className="modal-footer mt-2">
                            <button type="button" disabled={this.state.btnUploadDisabled} className="btn btn-primary" onClick={(e) => {this.confirmAssetUpload(e)}}>
                            {this.state.btnUploadDisabled ? 'Enviando...' : 'Confirmar'}
                            </button>
                        </div>
                </Modal>

                <Modal
                    class="modal-wrapper"
                    show={this.state.modalAdd}
                    close={(e) => {this.closeModal('add')}}
                    title='Adicionar música'>
                        <div className="modal-body">
                            <h6>Escolha a música: </h6>
                            <div className="form-group">
                            <Select
                                    placeholder="Selecione..."
                                    id="role" 
                                    defaultValue={this.state.existingAssetSelected}
                                    onChange={this.changeExistingAsset}
                                    options={this.state.existingAssets}
                                />
                            </div>
                        </div>
                        <div className="modal-footer mt-2">
                            <button type="button" disabled={this.state.btnAddDisabled} className="btn btn-primary" onClick={(e) => {this.confirmAssetAdd(e)}}>
                                {this.state.btnAddDisabled ? 'Adicionando...' : 'Confirmar'}
                            </button>
                        </div>
                </Modal>
            </div>
        )
    }
}

export default withRouter(Asset);
