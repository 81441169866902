import api from "../api";

class Language {

    //URL base para requisições na API
    baseUrl = process.env.REACT_APP_API_URL


    //lista de idiomas
    getLanguages = async() => {
        let success   = true;
        let log       = '';
        let languages = [];
        let url       = this.baseUrl+`/languages`;

        await fetch(url, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+api.getConnectUuid()
            },          
            method: 'GET',
        })
        .then((r) => r.json())
        .then((json) => {
 
            success = json.success;
            log = json.log;
            languages = json.languages

        }).catch(error => {

            success = false;
            log = 'Internal Server Error';

        });

        return {
            success: success,
            log: log,
            languages: languages
        };
    }

}

export default new Language();