import api from "../api";

class Contributor {

    //URL base para requisições na API
    baseUrl = process.env.REACT_APP_API_URL


    //lista de colaboradores
    getContributors = async() => {
        let success      = true;
        let log          = '';
        let contributors = [];
        let url          = this.baseUrl+`/contributors`;

        await fetch(url, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+api.getConnectUuid()
            },          
            method: 'GET',
        })
        .then((r) => r.json())
        .then((json) => {
 
            success = json.success;
            log = json.log;
            contributors = json.contributors

        }).catch(error => {

            success = false;
            log = 'Internal Server Error';

        });

        return {
            success: success,
            log: log,
            contributors: contributors
        };
    }


    //cadastro de colaborador
    createContributor = async(name, role, assetId) => {
        let success     = true;
        let log         = '';
        let contributor = [];
        let url         = this.baseUrl+`/contributors`;

        await fetch(url, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+api.getConnectUuid()
            },          
            method: 'POST',
            body: JSON.stringify({name: name, role:role, asset_id: assetId})
        })
        .then((r) => r.json())
        .then((json) => {
 
            success = json.success;
            log = json.log;
            contributor = json.contributor

        }).catch(error => {

            success = false;
            log = 'Internal Server Error';

        });

        return {
            success: success,
            log: log,
            contributor: contributor
        };
    }


    //lista de funções de colaboradores
    getContributorRoles = async() => {
        let success      = true;
        let log          = '';
        let roles        = [];
        let url          = this.baseUrl+`/contributors/roles`;

        await fetch(url, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+api.getConnectUuid()
            },          
            method: 'GET',
        })
        .then((r) => r.json())
        .then((json) => {
 
            success = json.success;
            log = json.log;
            roles = json.roles

        }).catch(error => {

            success = false;
            log = 'Internal Server Error';

        });

        return {
            success: success,
            log: log,
            roles: roles
        };
    }


    //exclusão de colaborador da música
    deleteContributorFromAsset = async(contributorId, assetId) => {
        let success     = true;
        let log         = '';
        let url         = this.baseUrl+`/contributors/`+contributorId+`/asset/`+assetId;

        await fetch(url, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+api.getConnectUuid()
            },          
            method: 'DELETE',
            body: JSON.stringify({})
        })
        .then((r) => r.json())
        .then((json) => {
 
            success = json.success;
            log = json.log;

        }).catch(error => {

            success = false;
            log = 'Internal Server Error';

        });

        return {
            success: success,
            log: log,
        };
    }
}

export default new Contributor();