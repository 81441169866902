import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import api from '../../../api';
import { toast } from 'react-toastify';
import CardMenu from '../../layouts/Auth/CardMenu';
import Loading from '../../plugins/Loading';
import ProductCover from './cover';
import ProductAssets from './assets';
import ProductMain from './main';
import ProductArtists from './artists';

const sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
}

class Product extends Component{

    constructor(props){
        super(props);
        this.state = {
            loader: true,
            apiUrl: process.env.REACT_APP_API_URL,
            sync: false,
            product: '',
            coverImage: '',
            coverImageUploading: false,
            languages: [],
            languageSelected: '',
            languageSelectedLabel: '',
            genres: [],
            genreSelected: '',
            genreSelectedLabel: '',
            alternateGenreSelected: '',
            alternateGenreSelectedLabel: '',
            subgenres: [],
            subgenreSelected: '',
            alternateSubgenreSelected: '',
            releaseFormatTypes: [
                { value: 'ALBUM', label: 'Álbum' },
                { value: 'SINGLE', label: 'Single' },
                { value: 'EP', label: 'EP' },
                { value: 'BOXSET', label: 'Boxset' },
            ],
            releaseFormatTypeSelected: '',
            releaseFormatTypeSelectedLabel: '',
            recordingYears: [],
            recordingYearSelectedLabel: '', 
            copyrightYears: [],
            cLineYearSelected: '',
            cLineYearSelectedLabel: '',
            pLineYearSelected: '',
            pLineYearSelectedLabel: '',
            consumerReleaseDateFormat: '',
            originalReleaseDateFormat: '',
        }

        this.loadProduct             = this.loadProduct.bind(this);
        this.saveProduct             = this.saveProduct.bind(this);
        this.changeValue             = this.changeValue.bind(this);
        this.changeLanguage          = this.changeLanguage.bind(this);
        this.changeGenre             = this.changeGenre.bind(this);
        this.changeAlternateGenre    = this.changeAlternateGenre.bind(this);
        this.changeSubgenre          = this.changeSubgenre.bind(this);
        this.changeAlternateSubgenre = this.changeAlternateSubgenre.bind(this);
        this.changeCoverImage        = this.changeCoverImage.bind(this); 
        this.changeReleaseFormatType = this.changeReleaseFormatType.bind(this); 
        this.changeRecordingYear     = this.changeRecordingYear.bind(this); 
        this.changeCLineYear         = this.changeCLineYear.bind(this); 
        this.changePLineYear         = this.changePLineYear.bind(this); 
        this.changeDateValue         = this.changeDateValue.bind(this); 
    }


    componentDidMount(){
        this.loadProduct();
    }

    /* Carrega os dados do produtos, gêneros, subgêneros e idiomas */
    loadProduct(){
        const {id} = this.props.match.params;

        api.getProduct(id).then(result => {
            
            if(!result.success)
            {
                return null;
            }
            else 
            {
                //atualiza os dados do produto
                this.setState({product: result.product});

                //atualiza a lista de assets
                this.setState({assets: result.product.assets});

                //atualiza a imagem da capa caso exista
                if(result.product.image !== '' && result.product.image !== null)
                {
                    this.setState({coverImage: result.product.image});
                }

                //busca os idiomas
                api.getLanguages().then(result => {
            
                    if(!result.success)
                    {
                        return [];
                    }
                    else 
                    {
                        let languages = [];
                        let defaultLabel = '';

                        for (let value in result.languages) {
                            let label  = result.languages[value];
                            let option = {
                                "value":value,"label":label
                            }
                            if(this.state.product.language == value)
                            {
                                defaultLabel = option.label;
                            }
                            languages.push(option);
                        }

                        this.setState({languages: languages}); 
                        this.setState({'languageSelected':this.state.product.language});
                        this.setState({languageSelectedLabel: defaultLabel}); 
                    }
                });
                
                //busca os gêneros
                api.getGenres().then(result => {
            
                    if(!result.success)
                    {
                        return [];
                    }
                    else 
                    {
                        let genres = [];
                        let defaultLabelGenre = '';
                        let defaultLabelAlternateGenre = '';

                        for (let key in result.genres) {
                            let value  = result.genres[key];
                            let option = {
                                "value":value.id,"label":value.name
                            }
                            if(this.state.product.genre !== null && typeof this.state.product.genre === 'object')
                            {
                                if(this.state.product.genre.id == option.value)
                                {
                                    defaultLabelGenre = option.label;
                                }
                            }
                            if(this.state.product.alternate_genre !== null && typeof this.state.product.alternate_genre === 'object')
                            {
                                if(this.state.product.alternate_genre.id == option.value)
                                {
                                    defaultLabelAlternateGenre = option.label;
                                }
                            }
                            genres.push(option);
                        }

                        this.setState({genres: genres}); 

                        if(this.state.product.genre !== null && typeof this.state.product.genre === 'object')
                        {
                            this.changeValue('genre',this.state.product.genre.id, false);
                            this.setState({'genreSelected':this.state.product.genre.id});
                            this.setState({'genreSelectedLabel':defaultLabelGenre});
                        }
                        if(this.state.product.alternate_genre !== null && typeof this.state.product.alternate_genre === 'object')
                        {
                            this.changeValue('alternate_genre',this.state.product.alternate_genre.id, false);
                            this.setState({'alternateGenreSelected': this.state.product.alternate_genre.id});
                            this.setState({'alternateGenreSelectedLabel': defaultLabelAlternateGenre});
                        }
                    }
                });
                
                //busca os subgêneros
                api.getSubgenres().then(result => {
            
                    if(!result.success)
                    {
                        return [];
                    }
                    else 
                    {
                        let subgenres = [];

                        for (let key in result.subgenres) {
                            let value  = result.subgenres[key];
                            let option = {
                                "value":value.id,"label":value.name
                            }
                            subgenres.push(option);
                        }
                        
                        this.setState({subgenres: subgenres}); 
                    }
                });


                //atualiza o releaseFormatType
                this.setState({'releaseFormatTypeSelected': this.state.product.release_format_type});
                this.setState({'releaseFormatTypeSelectedLabel': this.state.product.release_format_type});


                //gera as datas disponíveis para os campos "recording_year", "c_line_year" e "p_line_year"
                let recordingYears = [];
                let copyrightYears = [];

                for(let year = new Date().getFullYear(); year >= 1950; year--)
                {
                    let option = {
                        "value":year,"label":year
                    }
                    recordingYears.push(option);
                    copyrightYears.push(option);
                } 

                this.setState({recordingYears: recordingYears, copyrightYears: copyrightYears})
                this.setState({'cLineYearSelected':this.state.product.c_line_year});
                this.setState({'cLineYearSelectedLabel':this.state.product.c_line_year});
                this.setState({'pLineYearSelected':this.state.product.p_line_year});
                this.setState({'pLineYearSelectedLabel':this.state.product.p_line_year});

                //formata as datas para carregar os plugins
                var product = {...this.state.product}

                if(product.original_release_date !== null && product.original_release_date)
                {
                    this.setState({originalReleaseDateFormat: new Date(product.original_release_date)})
                }
                if(product.consumer_release_date !== null && product.consumer_release_date)
                {
                    this.setState({consumerReleaseDateFormat: new Date(product.consumer_release_date)})
                }


                //desabilita o loader da tela
                this.setState({loader :false});
            }
        });
    }

    /* função para alterar valor dos inputs referente ao álbum */
    changeValue(key, value, sync = true)
    {
        var product = {...this.state.product}
        product[key] = value;
        this.setState({product});

        if(sync === true)
        {
            var event = document.createEvent('Event');
            this.saveProduct(event);
        }
    }

    /* função para alterar valor do select "language" referente ao álbum */
    changeLanguage = selectedOption =>
    {
        this.changeValue('language',selectedOption.value);
        this.setState({'languageSelected':selectedOption.value});
        this.setState({'languageSelectedLabel':selectedOption.label});
    }

    /* função para alterar valor do select "genre" referente ao álbum */
    changeGenre = selectedOption =>
    {
        this.changeValue('genre',selectedOption.value);
        this.setState({'genreSelected':selectedOption.value});
        this.setState({'genreSelectedLabel':selectedOption.label});
    }

    /* função para alterar valor do select "alternate_genre" referente ao álbum */
    changeAlternateGenre = selectedOption =>
    {
        this.changeValue('alternate_genre',selectedOption.value);
        this.setState({'alternateGenreSelected':selectedOption.value});
        this.setState({'alternateGenreSelectedLabel':selectedOption.label});
    }

    /* função para alterar valor do select "subgenre" referente ao álbum */
    changeSubgenre = selectedOption =>
    {
        this.changeValue('subgenre',selectedOption.value);
        this.setState({'subgenreSelected':selectedOption.value});
    }

    /* função para alterar valor do select "alternate_subgenre" referente ao álbum */
    changeAlternateSubgenre = selectedOption =>
    {
        this.changeValue({'alternate_subgenre':selectedOption.value});
        this.setState({'alternateSubgenreSelected':selectedOption.value});
    }

    /* função para alterar valor do select "release_format_type" referente ao álbum */
    changeReleaseFormatType = selectedOption =>
    {
        this.changeValue('release_format_type',selectedOption.value);
        this.setState({'releaseFormatTypeSelected':selectedOption.value});
        this.setState({'releaseFormatTypeSelectedLabel':selectedOption.label});
    }

    /* função para alterar valor do select "recording_year" referente ao álbum */
    changeRecordingYear = selectedOption =>
    {
        this.changeValue('recording_year',selectedOption.value);
        this.setState({'recordingYearSelected':selectedOption.value});
        this.setState({'recordingYearSelectedLabel':selectedOption.label});
    }

    /* função para alterar valor do select "c_line_year" referente ao álbum */
    changeCLineYear = selectedOption =>
    {
        this.changeValue('c_line_year',selectedOption.value);
        this.setState({'cLineYearSelected':selectedOption.value});
        this.setState({'cLineYearSelectedLabel':selectedOption.label});
    }

    /* função para alterar valor do select "p_line_year" referente ao álbum */
    changePLineYear = selectedOption =>
    {
        this.changeValue('p_line_year',selectedOption.value);
        this.setState({'pLineYearSelected':selectedOption.value});
        this.setState({'pLineYearSelectedLabel':selectedOption.label});
    }

    /* função para alterar valor de um input com datepicker */
    changeDateValue(id, date)
    {
        let month   = date.getMonth() + 1;
        if(parseInt(month) < 10)
        {
            month = "0"+month;
        }
        let day   = date.getDate();
        if(parseInt(day) < 10)
        {
            day = "0"+day;
        }
        let newDate = date.getFullYear() +'-'+ month +'-'+ day;
        this.changeValue(id, newDate);
        newDate = new Date(newDate +' 12:00:00');
        if(id === 'original_release_date')
        {
            this.setState({'originalReleaseDateFormat':newDate});
        }
        else 
        {
            this.setState({'consumerReleaseDateFormat':newDate});
        }
    }

    /* função para alterar a foto de capa do álbum */
    changeCoverImage = async(e) => {

        e.preventDefault();
        const {product} = this.state;
        this.setState({coverImageUploading:true});
    
        let file = e.target.files[0];

        try 
        {
            var result = await api.updateProductCoverImage(product.id, file);

            if(!result.success)
            {
                toast.error(result.log);
            }
            else 
            {
                toast.success(result.log);

                this.setState({coverImage: result.image});
            }

            this.setState({coverImageUploading:false});

        }
        catch(err)
        {
            toast.error(err.message);

            this.setState({coverImageUploading:false});
        }

    } 

    /* função para salvar os dados do álbum */
    saveProduct = async(e) => {
        e.preventDefault();

        //apenas executa a função caso o álbum não esteja em sincronização
        const {sync} = this.state;

        if(!sync)
        {
            //atualiza o status da sincronização
            this.setState({sync: true});

            //adiciona um delay de 10 segundos para buscar os dados a serem sincronizados
            await sleep(10000)

            const{product} = this.state;

            try 
            {
                var result = await api.updateProduct(product, product.id);

                if(!result.success)
                {
                    toast.error(result.log);

                    //libera a função para ser executada novamente
                    this.setState({sync: false});

                    return null;
                }
                else 
                {
                    toast.success(result.log);

                    //libera a função para ser executada novamente
                    this.setState({sync: false});
                }
            }
            catch(err)
            {
                toast.error(err.message);
            
                //libera a função para ser executada novamente
                this.setState({sync: false});
            }

        }
        
    }

    

    render(){

        return (
            <div className="app-content container" id='main-div'>
                <div className="content-overlay"></div>
                <div className="header-navbar-shadow"></div>
                <div className="content-wrapper">
                    <div className="content-header row">
                    </div>
                    <div className="content-body">
                        <section>
                            <div>
                                <div className="d-flex flex-dir-column align-items-center">
                                    <CardMenu />
                                </div>
                            </div>
                        </section>

                        {this.state.loader === true ? (
                            <Loading type='spin' color='#f3f3f3' />

                        ) : (
                            <div>
                                <section>
                                    <div className="row row-no-margin">

                                        {/* Container de funções e foto de capa */}
                                        <ProductCover
                                            state={this.state} 
                                            loadProduct={this.loadProduct}
                                            changeValue={this.changeValue}
                                            changeCoverImage={this.changeCoverImage}                                    
                                        />   
                                

                                        <div className="col-sm-9">
                                            <div className="card">
                                                <div className="card-content">
                                                    <div className="card-body">
                                                        <ul className="nav nav-pills nav-justified">
                                                            <li className="nav-item product-tab-option">
                                                                <a className="nav-link active" id="tab-option-assets" data-toggle="pill" href="#tab-container-assets" aria-expanded="false">
                                                                    Faixas
                                                                </a>
                                                            </li>
                                                            <li className="nav-item product-tab-option">
                                                                <a className="nav-link" id="tab-option-info" data-toggle="pill" href="#tab-container-info" aria-expanded="true">
                                                                    Informações do álbum
                                                                </a>
                                                            </li>
                                                            <li className="nav-item product-tab-option">
                                                                <a className="nav-link" id="tab-option-artists" data-toggle="pill" href="#tab-container-artists" aria-expanded="true">
                                                                    Artistas
                                                                </a>
                                                            </li>
                                                        </ul>
                                                        <div className="tab-content">

                                                            {/* Container dos assets */}
                                                            <ProductAssets
                                                                state={this.state} 
                                                                loadProduct={this.loadProduct}
                                                                changeValue={this.changeValue}
                                                            />   

                                                            {/* Container de informações do álbum */}
                                                            <ProductMain
                                                                state={this.state} 
                                                                changeValue={this.changeValue}
                                                                changeLanguage={this.changeLanguage}
                                                                changeGenre={this.changeGenre}
                                                                changeAlternateGenre={this.changeAlternateGenre}
                                                                changeSubgenre={this.changeSubgenre}
                                                                changeAlternateSubgenre={this.changeAlternateSubgenre}
                                                                changeReleaseFormatType={this.changeReleaseFormatType}
                                                                changeRecordingYear={this.changeRecordingYear}
                                                                changeCLineYear={this.changeCLineYear}
                                                                changePLineYear={this.changePLineYear}
                                                                changeDateValue={this.changeDateValue}
                                                            />        


                                                            {/* Container de artistas */}
                                                            <ProductArtists
                                                                state={this.state} 
                                                                changeValue={this.changeValue}
                                                                loadProduct={this.loadProduct}
                                                            />                                             

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        )}
                        
                    </div>
                </div>
            </div>  
        
        );
    }
}

export default withRouter(Product);

