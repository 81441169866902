import React, { Component } from 'react';
import {Link, withRouter} from 'react-router-dom';
import api from '../../../../api';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

class Header extends Component{

    constructor(props)
    {
        super(props);
        this.state = {
            firstName: '',
            lastName: '',
            email: '',
            uploadSidebar: false,
        }

        this.getUserInfo = this.getUserInfo.bind(this);
    }


    componentWillMount()
    {
        this.getUserInfo();
    }


    //retorna os dados do usuário logado
    getUserInfo(){

        api.getUserInfo().then(result => {
            
      
          if(!result.success)
          {
              cookies.remove('connect.uuid', { path: '/' });

              return this.props.history.replace('/login');
            }
          else 
          {
              this.setState({firstName: result.user.first_name});
              this.setState({lastName: result.user.last_name});
              this.setState({email: result.user.email});
          }
      });
    }


    render(){
      return(
        <div id='header'>

          <nav className="header-navbar navbar-expand-lg navbar navbar-with-menu navbar-fixed navbar-brand-center mb-2">
            <div className="navbar-header d-xl-block d-none">
              <ul className="nav navbar-nav flex-row">
                <li className="nav-item">
                  <Link  to="/dashboard">
                      <img src='/app-assets/images/logo/logo_nikita_horizontal.jpeg' width='120' alt='' />
                  </Link>
                </li>
              </ul>
            </div>
            <div className="navbar-wrapper">
              <div className="navbar-container content">
                <div className="" >
          
                  <ul className="nav navbar-nav float-right">
                  
                    <li className="dropdown dropdown-user nav-item">
                      <a className="dropdown-toggle nav-link dropdown-user-link" href="#user" data-toggle="dropdown">
                        <div className="user-nav d-sm-flex d-none">
                          <span className="user-name text-bold-600">{this.state.firstName+' '+this.state.lastName}</span>
                          <span className="user-status">{this.state.email}</span>
                        </div>
                        <span><img className="round" src="/app-assets/images/icons/user.png" alt="avatar" height={40} width={40} /></span>
                      </a>
                      <div className="dropdown-menu dropdown-menu-right">
                          <Link className="dropdown-item" to='/profile'>
                              <i className="feather icon-user" /> Meu perfil
                          </Link>
                          
                          <Link className="dropdown-item" to='/password/update'>
                              <i className="fa fa-key" /> Alterar senha
                          </Link>
                          
                          <div className="dropdown-divider" />
                          <Link className="dropdown-item" to="/logout"><i className="feather icon-power" /> 
                              Sair
                          </Link>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

          </nav>

      </div>
        
      );
    }
}

export default withRouter(Header);