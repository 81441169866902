import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import api from '../../../api';
import { toast } from 'react-toastify';
import CardMenu from '../../layouts/Auth/CardMenu';

class UpdatePassword extends Component{


    constructor(props){
        super(props);
        this.state = {
            password: '',
            confirm_password: '',
        };

        this.updatePassword = this.updatePassword.bind(this);
    }

    componentDidMount()
    {
        //
    }


    /* update da senha de acesso */
    updatePassword = async(e) => {
        e.preventDefault();
        const {password, confirm_password} = this.state;
        try 
        {
            var result = await api.updatePassword(password, confirm_password);

            if(!result.success)
            {
                toast.error(result.log);

                return null;
            }
            else 
            {
                toast.success(result.log);
                this.props.history.replace('/dashboard');
            }
        }
        catch(err)
        {
            toast.error(err.message);
        }
    }

    render(){

        return (
            <div className="app-content container" id='main-div'>
                <div className="content-overlay"></div>
                <div className="header-navbar-shadow"></div>
                <div className="content-wrapper">
                    <div className="content-header row">
                    </div>
                    <div className="content-body">
                        <section>
                            <div>
                                <div className="d-flex flex-dir-column align-items-center">
                                    <CardMenu />
                                </div>
                            </div>
                        </section>

                        <section>
                            <div className="row row-no-margin">
                                <div className="col-xl-12">
                                    <div className="card" style={{width: '100%'}}>
                                        <div className="card-header pb-1">
                                            <div className="card-title">
                                                <h4 className="card-title">Alteração de senha</h4>
                                            </div>
                                        </div>
                                        <p className="px-2">Insira a nova senha e confirmação da nova senha, antes de confirmar.</p>
                                        <div className="card-content">
                                            <div className="card-body pt-1">
                                                <form onSubmit={this.updatePassword} method='POST'>
                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                            <fieldset className="form-label-group position-relative has-icon-left">
                                                                <input type="password" className="form-control" placeholder="Nova senha" required 
                                                                        name='password' onChange={(e) => {this.setState({password: e.target.value})}} />
                                                                <div className="form-control-position">
                                                                    <i className="feather icon-lock" />
                                                                </div>
                                                            </fieldset>
                                                        </div>

                                                        <div className="col-sm-6">
                                                            <fieldset className="form-label-group position-relative has-icon-left">
                                                                <input type="password" className="form-control" placeholder="Confirme sua senha" required 
                                                                        name='confirm_password' onChange={(e) => {this.setState({confirm_password: e.target.value})}} />
                                                                <div className="form-control-position">
                                                                    <i className="feather icon-lock" />
                                                                </div>
                                                            </fieldset>
                                                        </div>
                                                        <div className="col-sm-12">
                                                            <button type="submit" className="btn btn-primary float-right btn-inline mb-2">Confirmar</button>
                                                        </div>
                                                    </div>
                                                    
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </section>
                    </div>
                </div>
            </div>  
        
        );
    }
}

export default withRouter(UpdatePassword);