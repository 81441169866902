import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import api from '../../../api';
import { toast } from 'react-toastify';
import CardMenu from '../../layouts/Auth/CardMenu';
import Loading from '../../plugins/Loading';

class Profile extends Component{


    constructor(props){
        super(props);
        this.state = {
            loader: true,
            apiUrl: process.env.REACT_APP_API_URL,
            profile: '',
            artistOrBand: '',
            address: '',
            photo: '',
            photoUploading: false,
        };

        this.loadProfile = this.loadProfile.bind(this);
        this.changeValue = this.changeValue.bind(this);
        this.changePhoto = this.changePhoto.bind(this);
        this.updateProfile = this.updateProfile.bind(this);
    }

    componentWillMount()
    {
        this.loadProfile();
    }


    /* carrega o perfil do usuário */
    loadProfile(){
        api.getProfile().then(result => {
            this.setState({profile: result.profile})
            this.setState({artistOrBand: result.profile.artist_or_band})
            this.setState({photo: result.profile.artist_or_band.photo})
            this.setState({address: result.profile.address});



            //desabilita o loader da tela
            this.setState({loader :false});
        });
    }


    /* função para alterar valor dos inputs referente ao perfil */
    changeValue(key, value, entity)
    {
        if(entity == '')
        {
            var profile = {...this.state.profile}
            profile[key] = value;
            this.setState({profile});
        } 
        else if(entity == 'address')
        {
            var address = {...this.state.address}
            address[key] = value;
            this.setState({address});
        }
        else if(entity == 'artistOrBand')
        {
            var artistOrBand = {...this.state.artistOrBand}
            artistOrBand[key] = value;
            this.setState({artistOrBand});
        }
    }


    /* função para alterar a photo do perfil */
    changePhoto = async(e) =>
    {

        this.setState({photoUploading:true});
    
        let file = e.target.files[0];

        try 
        {
            var result = await api.updatePhoto(file);

            if(!result.success)
            {
                toast.error(result.log);
            }
            else 
            {
                toast.success(result.log);

                this.setState({photo: result.profile.artist_or_band.photo});
            }

            this.setState({photoUploading:false});

        }
        catch(err)
        {
            toast.error(err.message);

            this.setState({photoUploading:false});
        }


    }


    /* update do perfil do usuário */
    updateProfile = async(e) => {
        e.preventDefault();
        var {profile, artistOrBand, address} = this.state;
        try 
        {
            profile['address'] = address;
            profile['artist_or_band'] = artistOrBand;


            var result = await api.updateProfile(profile);

            if(!result.success)
            {
                toast.error(result.log);

                return null;
            }
            else 
            {
                toast.success(result.log);
            }
        }
        catch(err)
        {
            toast.error(err.message);
        }
    }

    render(){

        return (
            <div className="app-content container" id='main-div'>
                <div className="content-overlay"></div>
                <div className="header-navbar-shadow"></div>
                <div className="content-wrapper">
                    <div className="content-header row">
                    </div>
                    <div className="content-body">
                        <section>
                            <div>
                                <div className="d-flex flex-dir-column align-items-center">
                                    <CardMenu />
                                </div>
                            </div>
                        </section>

                        {this.state.loader === true ? (
                            <Loading type='spin' color='#f3f3f3' />

                        ) : (
                        <section>
                            <div className="row row-no-margin">
                                <div className="col-xl-12">
                                    <div className="card" style={{width: '100%'}}>
                                        <div className="card-header pb-1">
                                            <div className="card-title">
                                                <h4>Meu perfil</h4>
                                            </div>
                                        </div>
                                        <div className="card-content">
                                            <div className="card-body pt-1">
                                                <form onSubmit={this.updateProfile} method='POST'>


                                                    <div className="col-12 product-tab-subtitle">
                                                        Suas informações como banda ou artista
                                                    </div>
                                                    <div className="row mt-2">
                                                        <div className="col-sm-2">
                                                            <div id="photo-container">

                                                                <input type="file" 
                                                                    ref={input => this.photoInput = input}
                                                                    style={{display: 'none'}} 
                                                                    onChange={(e) =>{ this.changePhoto(e)}} />

                                                                <img className="photo-img img-fluid" src={this.state.photo !== '' ? this.state.apiUrl+this.state.photo : "/app-assets/images/icons/user.png"} alt=""/>
                                                        
                                                                {this.state.photoUploading === true ? (
                                                                    <div id="photo-trigger">
                                                                        Processando...
                                                                    </div>
                                                                ) : (
                                                                    <div id="photo-trigger" onClick={() => this.photoInput.click()}>
                                                                        Alterar foto
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-10">
                                                            <div>
                                                                <label>Nome artístico</label>
                                                                <input type="text" className="form-control" 
                                                                    value={this.state.artistOrBand.name}
                                                                    onChange={(e) => {this.changeValue('name',e.target.value,'artistOrBand')}}/>
                                                            </div>

                                                            <div className="mt-2">
                                                                <label>Website</label>
                                                                <input type="url" className="form-control" 
                                                                    value={this.state.profile.website}
                                                                    onChange={(e) => {this.changeValue('website',e.target.value,'')}}/>
                                                            </div>
                                                        </div>

                                                    </div>


                                                    <div className="col-12 product-tab-subtitle mt-4">
                                                        Dados de contato e acesso ao Nikita App
                                                    </div>

                                                    <div className="row mt-2">
                                                        <div className="col-sm-4">
                                                            <div>
                                                                <label>Seu nome</label>
                                                                <input type="text" className="form-control" 
                                                                    value={this.state.profile.first_name}
                                                                    onChange={(e) => {this.changeValue('first_name',e.target.value,'')}}/>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div>
                                                                <label>Seu sobrenome</label>
                                                                <input type="text" className="form-control" 
                                                                    value={this.state.profile.last_name}
                                                                    onChange={(e) => {this.changeValue('last_name',e.target.value,'')}}/>
                                                            </div>
                                                        </div>

                                                        <div className="col-sm-4">
                                                            <div>
                                                                <label>Fone de contato</label>
                                                                <input type="text" className="form-control" 
                                                                    value={this.state.profile.phone} maxLength="40"
                                                                    onChange={(e) => {this.changeValue('phone',e.target.value,'')}}/>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row mt-2">
                                                        <div className="col-sm-12">
                                                            <div>
                                                                <label>E-mail de acesso</label>
                                                                <input type="text" className="form-control" 
                                                                    value={this.state.profile.email} readOnly
                                                                    onChange={(e) => {this.changeValue('email',e.target.value,'')}}/>
                                                                    <small>OBS: informação não disponível para alteração</small>
                                                            </div>
                                                        </div>
                                                    </div>



                                                    <div className="col-12 product-tab-subtitle mt-4">
                                                        Endereço
                                                    </div>

                                                    <div className="row mt-2">
                                                        <div className="col-sm-6">
                                                            <div>
                                                                <label>Endereço</label>
                                                                <input type="text" className="form-control" 
                                                                    value={this.state.address.street} maxLength="100"
                                                                    onChange={(e) => {this.changeValue('street',e.target.value,'address')}}/>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3">
                                                            <div>
                                                                <label>Número</label>
                                                                <input type="text" className="form-control" 
                                                                    value={this.state.address.number} maxLength="10"
                                                                    onChange={(e) => {this.changeValue('number',e.target.value,'address')}}/>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3">
                                                            <div>
                                                                <label>Bairro</label>
                                                                <input type="text" className="form-control" 
                                                                    value={this.state.address.neighborhood} maxLength="60"
                                                                    onChange={(e) => {this.changeValue('neighborhood',e.target.value,'address')}}/>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div className="row mt-2">
                                                        <div className="col-sm-3">
                                                            <div>
                                                                <label>CEP</label>
                                                                <input type="text" className="form-control" 
                                                                    value={this.state.address.zipcode} maxLength="8"
                                                                    onChange={(e) => {this.changeValue('zipcode',e.target.value,'address')}}/>
                                                                    <small>OBS: insira apenas números</small>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3">
                                                            <div>
                                                                <label>Cidade</label>
                                                                <input type="text" className="form-control" 
                                                                    value={this.state.address.city} maxLength="64"
                                                                    onChange={(e) => {this.changeValue('city',e.target.value,'address')}}/>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3">
                                                            <div>
                                                                <label>Estado</label>
                                                                <input type="text" className="form-control" 
                                                                    value={this.state.address.state} maxLength="50"
                                                                    onChange={(e) => {this.changeValue('state',e.target.value,'address')}}/>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3">
                                                            <div>
                                                                <label>País</label>
                                                                <input type="text" className="form-control" 
                                                                    value={this.state.address.country} maxLength="40"
                                                                    onChange={(e) => {this.changeValue('country',e.target.value,'address')}}/>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row mt-4">
                                                        <div className="col-sm-12">
                                                            <button type="submit" className="btn btn-primary float-right btn-inline mb-2">Confirmar</button>
                                                        </div>
                                                    </div>
                                                    
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </section> 
                        )};
                    </div>
                </div>
            </div>  
        
        );
    }
}

export default withRouter(Profile);