import api from "../api";

class Password {

    //URL base para requisições na API
    baseUrl = process.env.REACT_APP_API_URL


    //recuperação de senha
    reset = async(email) => {
        let success  = true;
        let log      = '';
        let url      = this.baseUrl+`/password/reset`;

        await fetch(url, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },          
            method: 'POST',
            body: JSON.stringify({email: email})
        })
        .then((r) => r.json())
        .then((json) => {
 
            success = json.success;
            log = json.log;

        }).catch(error => {

            success = false;
            log = 'Internal Server Error';

        });

        return {
            success: success,
            log: log
        };
    }

    //alteração de senha
    update = async(password, confirmPassword) => {
        let success  = true;
        let log      = '';
        let url      = this.baseUrl+`/password/update`;

        await fetch(url, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+api.getConnectUuid()
            },          
            method: 'POST',
            body: JSON.stringify({password: password, confirm_password: confirmPassword})
        })
        .then((r) => r.json())
        .then((json) => {
 
            success = json.success;
            log = json.log;

        }).catch(error => {

            success = false;
            log = 'Internal Server Error';

        });

        return {
            success: success,
            log: log
        };
    }
}

export default new Password();