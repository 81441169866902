import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import Select from 'react-select';

class Main extends Component {

    render() {
        return (
            
            <div className="tab-pane active" id="tab-container-info" role="tabpanel" aria-labelledby="tab-option-info" aria-expanded="false">
                <div className="row match-height">
                    <div className="col-12">

                        <div className="col-12 product-tab-subtitle">
                            Informações principais
                        </div>

                        <div className="row mt-1">
                            <div className="col-md-6 col-12 pt-1">
                                <div>
                                    <label>Título da música</label>
                                    <input type="text" id="name" className="form-control" 
                                        value={this.props.state.asset.name}
                                        onChange={(e) => {this.props.changeValue('name',e.target.value)}}/>
                                </div>
                            </div>
                            <div className="col-md-6 col-12 pt-1">
                                <div>
                                    <label>ISRC</label>
                                    <input type="text" id="isrc" className="form-control" 
                                        value={this.props.state.asset.isrc} 
                                        onChange={(e) => {this.props.changeValue('isrc',e.target.value)}}/>
                                </div>
                            </div>
                            <div className="col-md-6 pt-1">
                                <div>
                                    <label>Artista exibido</label>
                                    <input type="text" id="display_artist" className="form-control"
                                        value={this.props.state.asset.display_artist}
                                        onChange={(e) => {this.props.changeValue('display_artist',e.target.value)}}/>
                                </div>
                            </div>

                            <div className="col-md-6 col-12 pt-1">
                                <div>
                                    <label>Idioma principal</label>
                                    <Select
                                        placeholder="Selecione..."
                                        id="language" 
                                        value={{label : this.props.state.languageSelectedLabel}}
                                        defaultValue={this.props.state.languageSelected}
                                        onChange={this.props.changeLanguage}
                                        options={this.props.state.languages}
                                        />
                                </div>
                            </div>

                            <div className="col-md-6 col-12 pt-1">
                                <div>
                                    <label>Gênero principal</label>
                                    <Select
                                        placeholder="Selecione..."
                                        id="genre" 
                                        value={{label : this.props.state.genreSelectedLabel}}
                                        defaultValue={this.props.state.genreSelected}
                                        onChange={this.props.changeGenre}
                                        options={this.props.state.genres}
                                        />
                                </div>
                            </div>

                            <div className="col-md-6 col-12 pt-1">
                                <div>
                                    <label>Gênero alternativo</label>
                                    <Select
                                        placeholder="Selecione..."
                                        id="alternate_genre" 
                                        value={{label : this.props.state.alternateGenreSelectedLabel}}
                                        defaultValue={this.props.state.alternateGenreSelected}
                                        onChange={this.props.changeAlternateGenre}
                                        options={this.props.state.genres}
                                        />
                                </div>
                            </div>
                        </div>

                        <div className="col-12 product-tab-subtitle mt-3">
                            Gravação e lançamento
                        </div>

                        <div className="row mt-1">

                            <div className="col-md-6 col-12 pt-1">
                                <div>
                                    <label>Versão da música</label>
                                    <input type="text" id="asset_version" className="form-control"
                                        value={this.props.state.asset.asset_version}
                                        onChange={(e) => {this.props.changeValue('asset_version',e.target.value)}}/>
                                </div>
                            </div>


                            <div className="col-md-6 col-12 pt-1">
                                <div>
                                    <label>Ano de gravação</label>
                                    <Select
                                        placeholder="Selecione..."
                                        id="recording_year" 
                                        value={{label : this.props.state.recordingYearSelectedLabel}}
                                        defaultValue={this.props.state.recordingYearSelected}
                                        onChange={this.props.changeRecordingYear}
                                        options={this.props.state.recordingYears}
                                        />
                                </div>
                            </div>
                            
                            {/*<div className="col-md-3 col-12 pt-1">
                                <div>
                                    <label>Data de lançamento da música</label><br />
                                    <DatePicker
                                        selected={this.props.state.assetReleaseDateFormat}
                                        dateFormat="dd/MM/yyyy"
                                        locale={pt_BR}
                                        className="form-control"
                                        id="asset_release_date"
                                        onChange={date => this.props.changeDateValue('asset_release_date',date)}
                                    />
                                </div>
                            </div>*/}


                            <div className="col-12 pt-1">
                                <fieldset className="checkbox">
                                    <div className="vs-checkbox-con vs-checkbox-primary">
                                        <input type="checkbox" id="parental_advisory" defaultChecked={this.props.state.asset.parental_advisory} onChange={(e) => { this.props.changeValue('parental_advisory', e.target.checked ? true : false) }} />
                                        <span className="vs-checkbox">
                                            <span className="vs-checkbox--check">
                                            <i className="vs-icon feather icon-check" />
                                            </span>
                                        </span>
                                        <span className>Possui conteúdo explícito</span>
                                    </div>
                                </fieldset>
                            </div>
                            
                          
                        </div>


                        <div className="col-12 product-tab-subtitle mt-3">
                            Direitos autorais
                        </div>

                        <div className="row mt-1">
                            <div className="col-md-4 col-12 pt-1">
                                <div>
                                    <label>℗ Copyright Ano</label>
                                    <Select
                                        placeholder="Selecione..."
                                        id="p_line_year" 
                                        value={{label : this.props.state.pLineYearSelectedLabel}}
                                        defaultValue={this.props.state.pLineYearSelected}
                                        onChange={this.props.changePLineYear}
                                        options={this.props.state.copyrightYears}
                                        />
                                </div>
                            </div>

                            <div className="col-md-8 col-12 pt-1">
                                <div>
                                    <label>℗ Copyright Texto</label>
                                    <input type="text" id="p_line_text" className="form-control" 
                                        value={this.props.state.asset.p_line_text}
                                        onChange={(e) => {this.props.changeValue('p_line_text',e.target.value)}}/>
                                </div>
                            </div>

                        </div>


                        <div className="col-12 product-tab-subtitle mt-3">
                            Informações adicionais
                        </div>
                        <div className="row mt-1">

                            <div className="col-md-12 pt-1">
                                <div>
                                    <label>Letra da música</label>
                                    <textarea type="text" id="lyrics" className="form-control"
                                        value={this.props.state.asset.label_copy_info} rows="10"
                                        onChange={(e) => {this.props.changeValue('lyrics',e.target.value)}}/>
                                </div>
                            </div>

                        
                        </div>
                    </div>
                </div>
            </div>
        
        )
    }
}

export default withRouter(Main);
