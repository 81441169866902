import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import api from '../../../api';
import Modal from '../../plugins/Modal';
import Autosuggest from 'react-autosuggest';
import { toast } from 'react-toastify';


class Artists extends Component {

    constructor(props){
        super(props);
        this.state = {
            modal: false,
            apiUrl: process.env.REACT_APP_API_URL,
            valuePublisher: '',
            valuePublishingHouse: '',
            product: props.state.product,
            asset: props.state.asset,
            suggestionsPublisher: [],
            suggestionsPublishingHouse: [],
            publishers: props.state.publishers,
            publishingHouses: props.state.publishingHouses,
        };

        this.openModal                    = this.openModal.bind(this);
        this.closeModal                   = this.closeModal.bind(this);
        this.confirmPublisher             = this.confirmPublisher.bind(this);
        this.getSuggestionsPublisher      = this.getSuggestionsPublisher.bind(this);
        this.getSuggestionValuePublisher  = this.getSuggestionValuePublisher.bind(this);
        this.renderSuggestionPublisher    = this.renderSuggestionPublisher.bind(this);
        this.getSuggestionsPublishingHouse     = this.getSuggestionsPublishingHouse.bind(this);
        this.getSuggestionValuePublishingHouse = this.getSuggestionValuePublishingHouse.bind(this);
        this.renderSuggestionPublishingHouse   = this.renderSuggestionPublishingHouse.bind(this);
        this.deletePublisher                   = this.deletePublisher.bind(this);
    }


    /* abre a modal de cadastro de colaborador */
    openModal(){
        this.setState({modal: true});
    }


    /* fecha a modal de cadastro de colaborador */
    closeModal(){
        this.setState({modal: false});
    }


    /* atualiza o valor da variável */
    onChangePublisher = (event, { newValue }) => {
        this.setState({
            valuePublisher: newValue
        });
    };

    onChangePublishingHouse = (event, { newValue }) => {
        this.setState({
            valuePublishingHouse: newValue
        });
    };


    /* atribui a editora a musica no webservice */
    confirmPublisher = async(e) => {
        e.preventDefault();
        const {valuePublisher, valuePublishingHouse} = this.state;
        try 
        {
            var result = await api.createPublisher(valuePublisher, valuePublishingHouse, this.state.asset.id);

            if(!result.success)
            {
                toast.error(result.log);

                return null;
            }
            else 
            {

                toast.success(result.log);
                
                this.setState({modal: false});

                this.props.loadAsset();
            }
        }
        catch(err)
        {
            toast.error(err.message);
        }
    }

    /* atribui a variável, as sugestões encontradas com getSuggestions */
    onSuggestionsFetchRequestedPublisher = ({ value }) => {
        this.setState({
            suggestionsPublisher: this.getSuggestionsPublisher(value)
        });
    };

    onSuggestionsFetchRequestedPublishingHouse = ({ value }) => {
        this.setState({
            suggestionsPublishingHouse: this.getSuggestionsPublishingHouse(value)
        });
    };

    /* limpa a variável de sugestões */
    onSuggestionsClearRequestedPublisher = () => {
        this.setState({
            suggestionsPublisher: []
        });
    };
    
    onSuggestionsClearRequestedPublishingHouse = () => {
        this.setState({
            suggestionsPublishingHouse: []
        });
    };

    /* busca as sugestões de acordo com o valor preenchido */
    getSuggestionsPublisher = value => {
        const inputValue  = value.trim();
        const inputLength = inputValue.length;

        if(inputLength === 0)
        {
            return [];
        }
        else 
        {
            const {publishers} = this.state;

            let items = [];

            for (let key in publishers) {
                let value  = publishers[key];
                let option = {
                    "value":value.value,"label":value.label
                }
                items.push(option);
            }
            
            return items.length === 0 ? [] : items.filter(item =>
                item.label.slice(0, inputLength) === inputValue
            );
        
        }
    };

    getSuggestionsPublishingHouse = value => {
        const inputValue  = value.trim();
        const inputLength = inputValue.length;

        if(inputLength === 0)
        {
            return [];
        }
        else 
        {
            const {publishingHouses} = this.state;

            console.log(publishingHouses)

            let items = [];

            for (let key in publishingHouses) {
                let value  = publishingHouses[key];
                let option = {
                    "value":value.value,"label":value.label
                }
                items.push(option);
            }
            
            return items.length === 0 ? [] : items.filter(item =>
                item.label.slice(0, inputLength) === inputValue
            );
        
        }
    };

    /* preenche o input ao clicar na sugestão exibida */
    getSuggestionValuePublisher = suggestion => suggestion.label;
    getSuggestionValuePublishingHouse = suggestion => suggestion.label;

    /* renderiza as sugestões */
    renderSuggestionPublisher = suggestion => (
        <div>
            {suggestion.label}
        </div>
    );

    renderSuggestionPublishingHouse = suggestion => (
        <div>
            {suggestion.label}
        </div>
    );

    /* exclui a editora da música */
    deletePublisher = async(e, publisherId) => {
        e.preventDefault();
        const {asset} = this.state;
        try 
        {
            var result = await api.deletePublisherFromAsset(publisherId, asset.id);

            if(!result.success)
            {
                toast.error(result.log);

                return null;
            }
            else 
            {
                toast.success(result.log);

                this.props.loadAsset();
            }
        }
        catch(err)
        {
            toast.error(err.message);
        }
    }

    render() {

        return (
            
            <div className="tab-pane" id="tab-container-publishers" role="tabpanel" aria-labelledby="tab-option-publishers" aria-expanded="false">
                <section>
                    <div className="row row-no-margin">
                    
                        <div className="col-xl-3 col-sm-3 col-sm-12 pt-1">
                            <div className="card-people-add" onClick={this.openModal}>
                                <div className="card-people-add-icon">
                                    <i className="feather icon-user-plus"></i>
                                </div>
                            </div>
                        
                        </div>

                        {this.props.state.asset.publishers.map((item) => {
                            return (
                                <div className="col-xl-3 col-sm-3 col-sm-12 pt-1" key={item.id}>
                                    <div className='bg-white p-1 card-people-item'>
                                        <h5 className="my-1 font-weight-700">{item.composer_or_lyricist.name}</h5>
                                        <small className="mb-2">{item.publishing_house.name}</small>
                                        <div className="card-people-item-footer">
                                            <button onClick={(e) => {this.deletePublisher(e, item.composer_or_lyricist.id)}} className="btn btn-sm btn-block btn-outline-primary">
                                                <i className="feather icon-trash"></i> Excluir
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}

                    </div>
                </section>

                <Modal
                    class="modal-wrapper"
                    show={this.state.modal}
                    close={this.closeModal}
                    title='Adicionar editora'>
                        <div className="modal-body">
                            <h6>Nome da editora: </h6>
                            <div className="form-group">
                                <Autosuggest
                                    className="form-control"
                                    suggestions={this.state.suggestionsPublisher}
                                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequestedPublisher}
                                    onSuggestionsClearRequested={this.onSuggestionsClearRequestedPublisher}
                                    getSuggestionValue={this.getSuggestionValuePublisher}
                                    renderSuggestion={this.renderSuggestionPublisher}
                                    inputProps={{
                                        placeholder: 'Digite o nome da editora',
                                        value: this.state.valuePublisher,
                                        onChange: this.onChangePublisher,
                                        className: "form-control"
                                    }}
                                />
                            </div>

                            <div>
                                <h6>Publishing house: </h6>
                                <div className="form-group">
                                    <Autosuggest
                                        className="form-control"
                                        suggestions={this.state.suggestionsPublishingHouse}
                                        onSuggestionsFetchRequested={this.onSuggestionsFetchRequestedPublishingHouse}
                                        onSuggestionsClearRequested={this.onSuggestionsClearRequestedPublishingHouse}
                                        getSuggestionValue={this.getSuggestionValuePublishingHouse}
                                        renderSuggestion={this.renderSuggestionPublishingHouse}
                                        inputProps={{
                                            placeholder: 'Digite o nome da publishing house',
                                            value: this.state.valuePublishingHouse,
                                            onChange: this.onChangePublishingHouse,
                                            className: "form-control"
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" onClick={(e) => {this.confirmPublisher(e)}}>Confirmar</button>
                        </div>
                </Modal>
            </div>
        
        )
    }
}

export default withRouter(Artists);
