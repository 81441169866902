import Cookies from 'universal-cookie';

//Webservices
import requestArtist from './requests/Artist';
import requestAsset from './requests/Asset';
import requestContributor from './requests/Contributor';
import requestGenre from './requests/Genre';
import requestLanguage from './requests/Language';
import requestLogin from './requests/Login';
import requestPassword from './requests/Password';
import requestProduct from './requests/Product';
import requestProfile from './requests/Profile';
import requestPublisher from './requests/Publisher';
import requestSubgenre from './requests/Subgenre';
import requestUser from './requests/User';

const cookies = new Cookies();

class Api {

    //URL base para requisições na API
    baseUrl = process.env.REACT_APP_API_URL

    //json de resposta padrão
    response = {
        success: '',
        log: '',
        data: '',
    }

    //retorna o Uuid da sessão
    getConnectUuid(){
        let uuid = cookies.get('connect.uuid');
        return typeof uuid == 'undefined' ? null : uuid;
    }
    
    //login e set de cookie connect.uuid
    login = async(email, password) => {
        return requestLogin.login(email, password);
    }

    //realiza logout do sistema
    logout(){
        cookies.remove('connect.uuid');
    }

    //recuperação de senha 
    resetPassword = async(email) => {
        return requestPassword.reset(email);
    }

    //alteração de senha 
    updatePassword = async(password, confirmPassword) => {
        return requestPassword.update(password, confirmPassword);
    }

    //retorna os dados do usuário logado
    getUserInfo(){
        return requestUser.getUserInfo();
    }

    //retorna os dados do perfil do usuário logado
    getProfile(){
        return requestProfile.getProfile();
    }

    //atualiza os dados do perfil do usuário
    updateProfile(data){
        return requestProfile.updateProfile(data);
    }

    //atualiza foto fo perfil
    updatePhoto(photo){
        return requestProfile.updatePhoto(photo);
    }

    //lista de idiomas
    getLanguages(){
        return requestLanguage.getLanguages();
    }

    //lista de gêneros
    getGenres(){
        return requestGenre.getGenres();
    }

    //lista de subgêneros
    getSubgenres(){
        return requestSubgenre.getSubgenres();
    }

    //lista e busca de álbuns
    getProducts(){
        return requestProduct.getProducts();
    }
 
    //cadastro de álbum
    createProduct = async(titulo) => {
        return requestProduct.create(titulo);
    }

    //busca de álbum
    getProduct(productId){
        return requestProduct.getProduct(productId);
    }

    //edição de álbum
    updateProduct(product, productId){
        return requestProduct.updateProduct(product, productId);
    }

    //reordena músicas do álbum
    sortAssets(productId, assets){
        return requestProduct.sortAssets(productId, assets);
    }

    //atualiza foto de capa do álbum
    updateProductCoverImage(productId, image){
        return requestProduct.updateProductCoverImage(productId, image);
    }

    //exclusão de álbum
    deleteProduct(productId){
        return requestProduct.deleteProduct(productId);
    }

    //submissão de álbum
    submitProduct(productId){
        return requestProduct.submitProduct(productId);
    }

    //publicação de álbum
    publishProduct(productId){
        return requestProduct.publishProduct(productId);
    }

    //clone de álbum
    cloneProduct(productId){
        return requestProduct.cloneProduct(productId);
    }
    
    //takedown de álbum
    takedownProduct(productId, message){
        return requestProduct.takedownProduct(productId, message);
    }

    //busca os artistas cadastrados de acordo com o valor digitado
    getArtists(name){
        return requestArtist.getArtists(name);
    }

    //cadastra artista e vincula ao produto e asset caso sejam informados
    createArtist(name, primary, productId, assetId){
        return requestArtist.createArtist(name, primary, productId, assetId);
    }

    //exclui artista do álbum informado
    deleteArtistFromProduct(artistId, productId){
        return requestArtist.deleteArtistFromProduct(artistId, productId);
    }

    //exclui artista da música informada
    deleteArtistFromAsset(artistId, assetId){
        return requestArtist.deleteArtistFromAsset(artistId, assetId);
    }

    //retorna músicas cadastradas
    getAssets(){
        return requestAsset.getAssets();
    }

    //cadastra asset e vincula ao produto caso sejam informado
    createAsset(name, isrc, file, productId){
        return requestAsset.createAsset(name, isrc, file, productId);
    }

    //adiciona música ao álbum informado
    addAssetToProduct(assetId, productId){
        return requestAsset.addAssetToProduct(assetId, productId);
    }

    //exclui música do álbum informado
    deleteAssetFromProduct(assetId, productId){
        return requestAsset.deleteAssetFromProduct(assetId, productId);
    }

    //retorna dados do asset
    getAsset(assetId){
        return requestAsset.getAsset(assetId);
    }

    //edição de música
    updateAsset(asset, assetId){
        return requestAsset.updateAsset(asset, assetId);
    }

    //retorna colaboradores
    getContributors(){
        return requestContributor.getContributors();
    }

    //cadastra colaboradores
    createContributor(name, role, assetId){
        return requestContributor.createContributor(name, role, assetId);
    }

    //retorna funções de colaboradores
    getContributorRoles(){
        return requestContributor.getContributorRoles();
    }

    //exclui colaborador da música
    deleteContributorFromAsset(contributorId, assetId){
        return requestContributor.deleteContributorFromAsset(contributorId, assetId);
    }

    //retorna editoras
    getPublishers(){
        return requestPublisher.getPublishers();
    }

    //cadastra editoras
    createPublisher(name, publishingHouse, assetId){
        return requestPublisher.createPublisher(name, publishingHouse, assetId);
    }

    //retorna publishing houses
    getPublishingHouses(){
        return requestPublisher.getPublishingHouses();
    }

    //exclui editora da música
    deletePublisherFromAsset(publisherId, assetId){
        return requestPublisher.deletePublisherFromAsset(publisherId, assetId);
    }

}

export default new Api();