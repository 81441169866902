import api from "../api";

class User {

    //URL base para requisições na API
    baseUrl = process.env.REACT_APP_API_URL


    //retorna os dados do usuário logado
    getUserInfo = async() => {
        let success  = true;
        let log      = '';
        let user     = null;
        let url      = this.baseUrl+`/user`;

        await fetch(url, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+api.getConnectUuid()
            },          
            method: 'GET',
        })
        .then((r) => r.json())
        .then((json) => {
 
            success = json.success;
            log = json.log;
            user = json.user

        }).catch(error => {

            success = false;
            log = 'Internal Server Error';

        });

        return {
            success: success,
            log: log,
            user: user
        };
    }
}

export default new User();