import api from "../api";

class Subgenre {

    //URL base para requisições na API
    baseUrl = process.env.REACT_APP_API_URL


    //lista de subgêneros
    getSubgenres = async() => {
        let success   = true;
        let log       = '';
        let subgenres = [];
        let url       = this.baseUrl+`/subgenres`;

        await fetch(url, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+api.getConnectUuid()
            },          
            method: 'GET',
        })
        .then((r) => r.json())
        .then((json) => {
 
            success = json.success;
            log = json.log;
            subgenres = json.subgenres

        }).catch(error => {

            success = false;
            log = 'Internal Server Error';

        });

        return {
            success: success,
            log: log,
            subgenres: subgenres
        };
    }

}

export default new Subgenre();