import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import api from '../../../api';
import Modal from '../../plugins/Modal';
import Autosuggest from 'react-autosuggest';
import { toast } from 'react-toastify';


class Artists extends Component {

    constructor(props){
        super(props);
        this.state = {
            modal: false,
            apiUrl: process.env.REACT_APP_API_URL,
            value: '',
            primary: false,
            product: props.state.product,
            asset: props.state.asset,
            suggestions: [],
            artists: []
        };

        this.openModal           = this.openModal.bind(this);
        this.closeModal          = this.closeModal.bind(this);
        this.changePrimary       = this.changePrimary.bind(this);
        this.confirmArtist       = this.confirmArtist.bind(this);
        this.getSuggestions      = this.getSuggestions.bind(this);
        this.getSuggestionvalue  = this.getSuggestionValue.bind(this);
        this.renderSuggestion    = this.renderSuggestion.bind(this);
        this.deleteArtist        = this.deleteArtist.bind(this);
    }


    /* abre a modal de cadastro de artista */
    openModal(){
        this.setState({modal: true});
    }


    /* fecha a modal de cadastro de artista */
    closeModal(){
        this.setState({modal: false});
    }


    /* atualiza o valor da variável */
    onChange = (event, { newValue }) => {
        this.setState({
            value: newValue
        });
    };


    /* atualiza artista se é principal ou não */
    changePrimary (newValue) {
        this.setState({
            primary: newValue
        });
    };


    /* atribui o artista a música no webservice */
    confirmArtist = async(e) => {
        e.preventDefault();
        const {value, primary} = this.state;

        try 
        {
            var result = await api.createArtist(value, primary, null, this.props.state.assetId);

            if(!result.success)
            {
                toast.error(result.log);

                return null;
            }
            else 
            {

                toast.success(result.log);
                
                this.setState({modal: false});

                this.props.loadAsset();
            }
        }
        catch(err)
        {
            toast.error(err.message);
        }
    }


    /* busca os artista no webservice de acordo com o nome digitado */
    getArtists = async(e) => {
        e.preventDefault();
        const {value} = this.state;
        
        try 
        {
            if(value.length > 0)
            {
                var result = await api.getArtists(value);

                this.setState({artists: result.artists});
            }
            else 
            {
                this.setState({artists: []});
            }
        }
        catch(err)
        {
            //
        }
    }

    
    /* atribui a variável, as sugestões encontradas com getSuggestions */
    onSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            suggestions: this.getSuggestions(value)
        });
    };

    /* limpa a variável de sugestões */
    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };

    /* busca as sugestões de acordo com o valor preenchido */
    getSuggestions = value => {
        const inputValue  = value.trim().toLowerCase();
        const inputLength = inputValue.length;

        if(inputLength === 0)
        {
            return [];
        }
        else 
        {
            var event = document.createEvent('Event');

            this.getArtists(event);
            const {artists} = this.state;
            
            return artists.length === 0 ? [] : artists.filter(item =>
                item.name.toLowerCase().slice(0, inputLength) === inputValue
            );
         
        }
    };
  
    /* preenche o input ao clicar na sugestão exibida */
    getSuggestionValue = suggestion => suggestion.name;
  
    /* renderiza as sugestões */
    renderSuggestion = suggestion => (
        <div>
        {suggestion.name}
        </div>
    );

    /* exclui o artista do álbum */
    deleteArtist = async(e, artistId) => {
        e.preventDefault();

        try 
        {
            var result = await api.deleteArtistFromAsset(artistId, this.props.state.assetId);

            if(!result.success)
            {
                toast.error(result.log);

                return null;
            }
            else 
            {
                toast.success(result.log);

                this.props.loadAsset();
            }
        }
        catch(err)
        {
            toast.error(err.message);
        }
    }

    render() {

        return (
            
            <div className="tab-pane" id="tab-container-artists" role="tabpanel" aria-labelledby="tab-option-artists" aria-expanded="false">
                    <div className="row row-no-margin">
                    
                        <div className="col-xl-3 col-sm-3 col-sm-12 pt-1">
                            <div className="card-people-add" onClick={this.openModal}>
                                <div className="card-people-add-icon">
                                    <i className="feather icon-user-plus"></i>
                                </div>
                            </div>
                        
                        </div>

                        {this.props.state.asset.artists.map((item) => {
                            return (
                                <div className="col-xl-3 col-sm-3 col-sm-12 pt-1" key={item.id}>
                                    <div className='bg-white p-1 card-people-item'>
                                        <h5 className="my-1 font-weight-700">{item.name}</h5>
                                        <small className="mb-2">{item.primary ? 'Principal' : 'Outros'}</small>
                                        <div className="card-people-item-footer">
                                            <button onClick={(e) => {this.deleteArtist(e, item.id)}} className="btn btn-sm btn-block btn-outline-primary">
                                                <i className="feather icon-trash"></i> Excluir
                                            </button>
                                        </div>
                                    </div>
                                
                                </div>
                            );
                        })}

                    </div>

                <Modal
                    class="modal-wrapper"
                    show={this.state.modal}
                    close={this.closeModal}
                    title='Adicionar artista'>
                        <div className="modal-body">
                            <h6>Nome do artista: </h6>
                            <div className="form-group">
                                <Autosuggest
                                    className="form-control"
                                    suggestions={this.state.suggestions}
                                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                    onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                    getSuggestionValue={this.getSuggestionValue}
                                    renderSuggestion={this.renderSuggestion}
                                    inputProps={{
                                        placeholder: 'Digite o nome do artista',
                                        value: this.state.value,
                                        onChange: this.onChange,
                                        className: "form-control"
                                    }}
                                />
                            </div>

                            <div>
                                <fieldset className="checkbox">
                                    <div className="vs-checkbox-con vs-checkbox-primary">
                                        <input type="checkbox" id="artist_primary" onChange={(e) => {this.changePrimary(e.target.checked)}}/>
                                        <span className="vs-checkbox">
                                            <span className="vs-checkbox--check">
                                            <i className="vs-icon feather icon-check" />
                                            </span>
                                        </span>
                                        <span className>Artista principal</span>
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" onClick={(e) => {this.confirmArtist(e)}}>Confirmar</button>
                        </div>
                </Modal>
            </div>
        
        )
    }
}

export default withRouter(Artists);
