import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import api from '../../../api';
import { toast } from 'react-toastify';
import CardMenu from '../../layouts/Auth/CardMenu';
import Loading from '../../plugins/Loading';
import Modal from '../../plugins/Modal';

export default class Dashboard extends Component{


    constructor(props){
        super(props);
        this.state = {
            loader: true,
            modal: false,
            products: [],
            titulo: '',
            apiUrl: process.env.REACT_APP_API_URL
        };

        this.getProducts = this.getProducts.bind(this);
        this.postProduct = this.postProduct.bind(this);
        this.openModal   = this.openModal.bind(this);
        this.closeModal  = this.closeModal.bind(this);
    }

    componentDidMount()
    {
        //
    }

    componentWillMount()
    {
        this.getProducts();
    }

    /* Lista os álbuns do usuário logado */
    getProducts() {

        api.getProducts().then(result => {
            
            if(!result.success)
            {
                return null;
            }
            else 
            {
                this.setState({products: result.products});
                this.setState({loader: false});
            }
        });
    }

    /* abre a modal de cadastro de álbum */
    openModal(){
        this.setState({modal: true});
    }

    /* fecha a modal de cadastro de álbum */
    closeModal(){
        this.setState({modal: false});
    }

    /* post para cadastrar o álbum */
    postProduct = async(e) => {
        e.preventDefault();
        const {titulo} = this.state;
        try 
        {
            var result = await api.createProduct(titulo);

            this.closeModal();

            if(!result.success)
            {
                toast.error(result.log);

                return null;
            }
            else 
            {
                toast.success(result.log);

                //recarrega lista de produtos
                this.getProducts();
            }
        }
        catch(err)
        {
            toast.error(err.message);
        }
    }

    render(){

        return (

            <div className="app-content container" id='main-div'>
                <div className="content-overlay"></div>
                <div className="header-navbar-shadow"></div>
                <div className="content-wrapper">
                    <div className="content-header row">
                    </div>
                    <div className="content-body">

                        <section>
                            <div>
                                <div className="d-flex flex-dir-column align-items-center">
                                    <CardMenu />
                                </div>
                            </div>
                        </section>

                        {this.state.loader === true ? (
                            <Loading type='spin' color='#f3f3f3' />
                        ) : (

                            <section>
                                <div className="row row-no-margin">
                                
                                    <div className="col-xl-2 col-sm-3 col-sm-12 pt-1">
                                        
                                        <img className="card-img card-product-dashboard-img" id='product-img-add' src="/app-assets/images/icons/disco.png" alt="" />
                                        <div className='bg-white p-1 card-product-dashboard-info' id='product-info-add'>
                                            <div className="card-btns d-flex justify-content-between position-bottom pb-1">
                                                <button type='button' onClick={this.openModal} className="btn btn-block btn-primary float-right px-2">
                                                    Novo álbum
                                                </button>
                                            </div>
                                        </div>
                                    
                                    </div>

                                    {this.state.products.map((item) => {
                                        return (
                                            <div className="col-xl-2 col-sm-3 col-sm-12 pt-1" key={item.id}>
                                        
                                                <img className="card-img card-product-dashboard-img" src={item.image ? this.state.apiUrl+item.image : "/app-assets/images/icons/disco.png"} alt="" />
                                                <div className='bg-white p-1 card-product-dashboard-info'>
                                                    <h5 className="my-1 font-weight-700">{item.name}</h5>
                                                    <small className="mb-2">{item.display_artist ? item.display_artist : 'Artista não informado'}</small>
                                                    <div className="card-btns d-flex justify-content-between position-bottom pb-1">
                                                        <Link to={`/products/${item.id}`} className="btn btn-block btn-outline-primary float-right">Ver álbum</Link>
                                                    </div>
                                                </div>
                                            
                                            </div>
                                        );
                                    })}

                                </div>
                            </section>

                        )}

                    </div>
                </div>

                
                <Modal
                    class="modal-wrapper"
                    show={this.state.modal}
                    close={this.closeModal}
                    title='Novo álbum'>
                        <form method='POST' onSubmit={this.postProduct}>
                            <div className="modal-body">
                                <h6>Título do álbum: </h6>
                                <div className="form-group">
                                    <input type="text"  className="form-control" name='titulo' 
                                    onChange={(e) => {this.setState({titulo: e.target.value})}} />
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="submit" className="btn btn-primary" data-dismiss="modal">Confirmar</button>
                            </div>
                        </form>
                </Modal>

            </div>  
        
        );
    }
}