import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import pt_BR from 'date-fns/locale/pt-BR';

class Main extends Component {

    render() {
        return (
            
            <div className="tab-pane" id="tab-container-info" role="tabpanel" aria-labelledby="tab-option-info" aria-expanded="false">
                <div className="row match-height">
                    <div className="col-12">

                        <div className="col-12 product-tab-subtitle">
                            Informações principais
                        </div>

                        <div className="row mt-1">
                            <div className="col-md-6 col-12 pt-1">
                                <div>
                                    <label>Nome do álbum</label>
                                    <input type="text" id="name" className="form-control" 
                                        value={this.props.state.product.name}
                                        onChange={(e) => {this.props.changeValue('name',e.target.value)}}/>
                                </div>
                            </div>
                            <div className="col-md-6 col-12 pt-1">
                                <div>
                                    <label>UPC/EAN</label>
                                    <input type="text" id="upc" className="form-control" 
                                        value={this.props.state.product.upc} readOnly
                                        onChange={(e) => {this.props.changeValue('upc',e.target.value)}}/>
                                </div>
                            </div>
                            <div className="col-md-12 pt-1">
                                <div>
                                    <label>Artista exibido</label>
                                    <input type="text" id="display_artist" className="form-control"
                                        value={this.props.state.product.display_artist}
                                        onChange={(e) => {this.props.changeValue('display_artist',e.target.value)}}/>
                                </div>
                            </div>
                            <div className="col-md-6 col-12 pt-1">
                                <div>
                                    <label>Nº do catálogo</label>
                                    <input type="text" id="catalog_number" className="form-control"
                                        value={this.props.state.product.catalog_number}
                                        onChange={(e) => {this.props.changeValue('catalog_number',e.target.value)}}/>
                                </div>
                            </div>

                            <div className="col-md-6 col-12 pt-1">
                                <div>
                                    <label>Idioma principal</label>
                                    <Select
                                        placeholder="Selecione..."
                                        id="language" 
                                        value={{label : this.props.state.languageSelectedLabel}}
                                        defaultValue={this.props.state.languageSelected}
                                        onChange={this.props.changeLanguage}
                                        options={this.props.state.languages}
                                        />
                                </div>
                            </div>

                            <div className="col-md-6 col-12 pt-1">
                                <div>
                                    <label>Gênero principal {this.props.state.genreSelected}</label>
                                    <Select
                                        placeholder="Selecione..."
                                        id="genre" 
                                        value={{label : this.props.state.genreSelectedLabel}}
                                        defaultValue={this.props.state.genreSelected}
                                        onChange={this.props.changeGenre}
                                        options={this.props.state.genres}
                                        />
                                </div>
                            </div>

                            <div className="col-md-6 col-12 pt-1">
                                <div>
                                    <label>Gênero alternativo</label>
                                    <Select
                                        placeholder="Selecione..."
                                        id="alternate_genre" 
                                        value={{label : this.props.state.alternateGenreSelectedLabel}}
                                        defaultValue={this.props.state.alternateGenreSelected}
                                        onChange={this.props.changeAlternateGenre}
                                        options={this.props.state.genres}
                                        />
                                </div>
                            </div>
                        </div>

                        <div className="col-12 product-tab-subtitle mt-3">
                            Gravação e lançamento
                        </div>

                        <div className="row mt-1">

                            <div className="col-md-4 col-12 pt-1">
                                <div>
                                    <label>Versão de lançamento</label>
                                    <input type="text" id="release_version" className="form-control"
                                        value={this.props.state.product.release_version}
                                        onChange={(e) => {this.props.changeValue('release_version',e.target.value)}}/>
                                </div>
                            </div>

                            <div className="col-md-4 col-12 pt-1">
                                <div>
                                    <label>Formato</label>
                                    <Select
                                        placeholder="Selecione..."
                                        id="release_format_type" 
                                        value={{label : this.props.state.releaseFormatTypeSelectedLabel}}
                                        defaultValue={this.props.state.releaseFormatTypeSelected}
                                        onChange={this.props.changeReleaseFormatType}
                                        options={this.props.state.releaseFormatTypes}
                                        />
                                </div>
                            </div>

                            <div className="col-md-4 col-12 pt-1">
                                <div>
                                    <label>Quantidade de volumes</label>
                                    <input type="text" id="total_volumes" className="form-control"
                                        value={this.props.state.product.total_volumes}
                                        onChange={(e) => {this.props.changeValue('total_volumes',e.target.value)}}/>
                                </div>
                            </div>

                            <div className="col-12 mt-2">
                                <fieldset className="checkbox">
                                    <div className="vs-checkbox-con vs-checkbox-primary">
                                        <input type="checkbox" id="parental_advisory" defaultChecked={this.props.state.product.parental_advisory} onChange={(e) => { this.props.changeValue('parental_advisory', e.target.checked ? true : false) }} />
                                        <span className="vs-checkbox">
                                            <span className="vs-checkbox--check">
                                            <i className="vs-icon feather icon-check" />
                                            </span>
                                        </span>
                                        <span className>Possui conteúdo explícito</span>
                                    </div>
                                </fieldset>
                            </div>
                 

                            <div className="col-md-6 col-12 pt-1">
                                <div>
                                    <label>Data de lançamento oficial</label>
                                    <DatePicker
                                        selected={this.props.state.originalReleaseDateFormat}
                                        dateFormat="dd/MM/yyyy"
                                        locale={pt_BR}
                                        className="form-control"
                                        id="original_release_date"
                                        onChange={date => this.props.changeDateValue('original_release_date',date)}
                                    />
                                </div>
                            </div>

                            <div className="col-md-6 col-12 pt-1">
                                <div>
                                    <label>Data de lançamento p/ consumidor</label>
                                    <DatePicker
                                        selected={this.props.state.consumerReleaseDateFormat}
                                        dateFormat="dd/MM/yyyy"
                                        locale={pt_BR}
                                        className="form-control"
                                        id="consumer_release_date"
                                        onChange={date => this.props.changeDateValue('consumer_release_date',date)}
                                    />
                                </div>
                            </div>
                        </div>


                        <div className="col-12 product-tab-subtitle mt-3">
                            Direitos autorais
                        </div>

                        <div className="row mt-1">
                            <div className="col-md-4 col-12 pt-1">
                                <div>
                                    <label>© Copyright Ano</label>
                                    <Select
                                        placeholder="Selecione..."
                                        id="c_line_year" 
                                        value={{label : this.props.state.cLineYearSelectedLabel}}
                                        defaultValue={this.props.state.cLineYearSelected}
                                        onChange={this.props.changeCLineYear}
                                        options={this.props.state.copyrightYears}
                                        />
                                </div>
                            </div>

                            <div className="col-md-8 col-12 pt-1">
                                <div>
                                    <label>© Copyright Texto</label>
                                    <input type="text" id="c_line_text" className="form-control" 
                                        value={this.props.state.product.c_line_text}
                                        onChange={(e) => {this.props.changeValue('c_line_text',e.target.value)}}/>
                                </div>
                            </div>

                            <div className="col-md-4 col-12 pt-1">
                                <div>
                                    <label>℗ Copyright Ano</label>
                                    <Select
                                        placeholder="Selecione..."
                                        id="p_line_year" 
                                        value={{label : this.props.state.pLineYearSelectedLabel}}
                                        defaultValue={this.props.state.pLineYearSelected}
                                        onChange={this.props.changePLineYear}
                                        options={this.props.state.copyrightYears}
                                        />
                                </div>
                            </div>

                            <div className="col-md-8 col-12 pt-1">
                                <div>
                                    <label>℗ Copyright Texto</label>
                                    <input type="text" id="p_line_text" className="form-control" 
                                        value={this.props.state.product.p_line_text}
                                        onChange={(e) => {this.props.changeValue('p_line_text',e.target.value)}}/>
                                </div>
                            </div>

                        </div>


                        <div className="col-12 product-tab-subtitle mt-3">
                            Informações adicionais
                        </div>
                        <div className="row mt-1">

                            <div className="col-md-12 pt-1">
                                <div>
                                    <label>Sobre</label>
                                    <textarea type="text" id="label_copy_info" className="form-control"
                                        value={this.props.state.product.label_copy_info} rows="5"
                                        onChange={(e) => {this.props.changeValue('label_copy_info',e.target.value)}}/>
                                </div>
                            </div>

                            <div className="col-md-12 pt-1">
                                <div>
                                    <label>Observações internas</label>
                                    <textarea type="text" id="album_notes" className="form-control"
                                        value={this.props.state.product.album_notes} rows="5"
                                        onChange={(e) => {this.props.changeValue('album_notes',e.target.value)}}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        
        )
    }
}

export default withRouter(Main);
