import Cookies from 'universal-cookie';
const cookies = new Cookies();

class Login {

    //URL base para requisições na API
    baseUrl = process.env.REACT_APP_API_URL

    //login e set de cookie connect.uuid
    login = async(email, password) => {
        let success  = true;
        let log      = '';
        let url      = this.baseUrl+`/login`;

        await fetch(url, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },          
            method: 'POST',
            body: JSON.stringify({email: email, password: password, connect_sid: 'true'})
        })
        .then((r) => r.json())
        .then((json) => {
    
            if(json.success)
            {
                cookies.set('connect.uuid', json.token, { path: '/' });
                //cookies.set('connect.sid', json.connect_sid, { path: '/', domain: 'https://login.nikita.com.br' });
            }
            success = json.success;
            log = json.log;

        }).catch(error => {
            success = false;
            log = 'Internal Server Error';
        });

        return {
            success: success,
            log: log
        };
    }
}

export default new Login();