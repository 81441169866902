import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import api from '../../../api';
import { toast } from 'react-toastify';
import CardMenu from '../../layouts/Auth/CardMenu';
import Loading from '../../plugins/Loading';
import Select from 'react-select';
import AssetMain from './main';
import AssetArtists from './artists';
import AssetContributors from './contributors';
import AssetPublishers from './publishers';

const sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
}

class Asset extends Component{


    constructor(props){
        super(props);
        this.state = {
            loader: true,
            productId: this.props.match.params.id,
            assetId: this.props.match.params.assetId,
            sync: false,
            asset: '',
            product: '',
            tracks: [],
            trackSelected: '',
            trackSelectedLabel: '',
            contributors: [],
            contributorRoles: [],
            contributorRoleSelected: '',
            publishers: [],
            publishingHouses: [],
            publishingHouseSelected: '',
            languages: [],
            languageSelected: '',
            languageSelectedLabel: '',
            genres: [],
            genreSelected: '',
            genreSelectedLabel: '',
            alternateGenreSelected: '',
            alternateGenreSelectedLabel: '',
            recordingYears: [],
            recordingYearSelected: '',
            recordingYearSelectedLabel: '',
            copyrightYears: [],
            pLineYearSelected: '',
            pLineYearSelectedLabel: '',
            assetReleaseDateFormat: '',
        }

        this.loadAsset = this.loadAsset.bind(this);
        this.saveAsset = this.saveAsset.bind(this);
        this.changeTrack = this.changeTrack.bind(this);
        this.changeValue = this.changeValue.bind(this);
        this.changeLanguage          = this.changeLanguage.bind(this);
        this.changeGenre             = this.changeGenre.bind(this);
        this.changeAlternateGenre    = this.changeAlternateGenre.bind(this);
        this.changeRecordingYear     = this.changeRecordingYear.bind(this); 
        this.changePLineYear         = this.changePLineYear.bind(this); 
        this.changeDateValue         = this.changeDateValue.bind(this); 
    }

    componentDidMount(){
        this.loadAsset();
    }

    
    loadAsset(){
        api.getAsset(this.state.assetId).then(result => {
            
            if(!result.success)
            {
                return null;
            }
            else 
            {
                //atualiza os dados do asset
                this.setState({asset: result.asset});

                //busca dados do álbum
                api.getProduct(this.state.productId).then(result2 => {

                    this.setState({product: result2.product});

                    let tracks = [];

                    //carrega a lista de assets do produto
                    let defaultLabel = '';

                    for (let i = 0; i < result2.product.assets.length; i++) {
                        let track = result2.product.assets[i];
                        let option = {
                            "value":track.id,"label":track.name + "    ("+track.sequence+"/"+result2.product.total_assets+")"
                        }

                        if(this.state.assetId == track.id)
                        {
                            defaultLabel = option.label;
                        }
                        tracks.push(option);
                    }

                    this.setState({tracks: tracks}); 
                    this.setState({trackSelected: this.state.assetId}); 
                    this.setState({trackSelectedLabel: defaultLabel}); 

                    //desabilita o loader da tela apenas após carregar os dados do produto
                    this.setState({loader :false});
                });

                //busca os idiomas
                api.getLanguages().then(result => {
            
                    if(!result.success)
                    {
                        return [];
                    }
                    else 
                    {
                        let languages = [];
                        let defaultLabel = '';

                        for (let value in result.languages) {
                            let label  = result.languages[value];
                            let option = {
                                "value":value,"label":label
                            }
                            if(this.state.asset.language == value)
                            {
                                defaultLabel = option.label;
                            }
                            languages.push(option);
                        }

                        this.setState({languages: languages}); 
                        this.setState({'languageSelected':this.state.asset.language});
                        this.setState({languageSelectedLabel: defaultLabel}); 

                    }
                });
                
                //busca os gêneros
                api.getGenres().then(result => {
            
                    if(!result.success)
                    {
                        return [];
                    }
                    else 
                    {
                        let genres = [];
                        let defaultLabelGenre = '';
                        let defaultLabelAlternateGenre = '';
                    

                        for (let key in result.genres) {
                            let value  = result.genres[key];
                            let option = {
                                "value":value.id,"label":value.name
                            }
                            if(this.state.asset.genre !== null && typeof this.state.asset.genre === 'object')
                            {
                                if(this.state.asset.genre.id == option.value)
                                {
                                    defaultLabelGenre = option.label;
                                }
                            }
                            if(this.state.asset.alternate_genre !== null && typeof this.state.asset.alternate_genre === 'object')
                            {
                                if(this.state.asset.alternate_genre.id == option.value)
                                {
                                    defaultLabelAlternateGenre = option.label;
                                }
                            }
                            
                            
                            genres.push(option);
                        }

                        this.setState({genres: genres}); 

                        if(this.state.asset.genre !== null && typeof this.state.asset.genre === 'object')
                        {
                            this.changeValue('genre',this.state.asset.genre.id, false);
                            this.setState({'genreSelected':this.state.asset.genre.id});
                            this.setState({'genreSelectedLabel':defaultLabelGenre});
                        }
                        if(this.state.asset.alternate_genre !== null && typeof this.state.asset.alternate_genre === 'object')
                        {
                            this.changeValue('alternate_genre',this.state.asset.alternate_genre.id, false);
                            this.setState({'alternateGenreSelected': this.state.asset.alternate_genre.id});
                            this.setState({'alternateGenreSelectedLabel': defaultLabelAlternateGenre});
                        }
                    }
                });
                
                //busca os subgêneros
                api.getSubgenres().then(result => {
            
                    if(!result.success)
                    {
                        return [];
                    }
                    else 
                    {
                        let subgenres = [];

                        for (let key in result.subgenres) {
                            let value  = result.subgenres[key];
                            let option = {
                                "value":value.id,"label":value.name
                            }
                            subgenres.push(option);
                        }
                        
                        this.setState({subgenres: subgenres}); 
                    }
                });

                //busca os colaboradores
                api.getContributors().then(result => {
            
                    if(!result.success)
                    {
                        return [];
                    }
                    else 
                    {
                        let contributors = [];

                        for (let key in result.contributors) {
                            let value  = result.contributors[key];
                            let option = {
                                "value":value.id,"label":value.name
                            }
                            contributors.push(option);
                        }
                        
                        this.setState({contributors: contributors}); 
                    }
                });


                //busca as funções dos colaboradores
                api.getContributorRoles().then(result => {
            
                    if(!result.success)
                    {
                        return [];
                    }
                    else 
                    {
                        let contributorRoles = [];

                        for (let key in result.roles) {
                            let value  = result.roles[key];
                            let option = {
                                "value":value.id,"label":value.name
                            }
                            contributorRoles.push(option);
                        }
                        
                        this.setState({contributorRoles: contributorRoles}); 
                    }
                });

                //busca as editoras
                api.getPublishers().then(result => {
            
                    if(!result.success)
                    {
                        return [];
                    }
                    else 
                    {
                        let publishers = [];

                        for (let key in result.publishers) {
                            let value  = result.publishers[key];
                            let option = {
                                "value":value.id,"label":value.name
                            }
                            publishers.push(option);
                        }
                        
                        this.setState({publishers: publishers}); 
                    }
                });

                //busca as publishing houses
                api.getPublishingHouses().then(result => {
            
                    if(!result.success)
                    {
                        return [];
                    }
                    else 
                    {
                        let publishingHouses = [];

                        for (let key in result.houses) {
                            let value  = result.houses[key];
                            let option = {
                                "value":value.id,"label":value.name
                            }
                            publishingHouses.push(option);
                        }
                        
                        this.setState({publishingHouses: publishingHouses}); 
                    }
                });

                //gera as datas disponíveis para os campos "recording_year" e "p_line_year"
                let recordingYears = [];
                let copyrightYears = [];

                for(let year = new Date().getFullYear(); year >= 1950; year--)
                {
                    let option = {
                        "value":year,"label":year
                    }
                    recordingYears.push(option);
                    copyrightYears.push(option);
                } 
                
                this.setState({recordingYears: recordingYears, copyrightYears: copyrightYears})
                this.setState({'recordingYearSelectedLabel':this.state.asset.recording_year});
                this.setState({'pLineYearSelectedLabel':this.state.asset.p_line_year});

                //formata as datas para carregar os plugins
                var asset = {...this.state.asset}
                if(asset.asset_release_date !== null && asset.asset_release_date)
                {
                    this.setState({assetReleaseDateFormat: new Date(asset.asset_release_date)})
                }
                
            }
        });
    }

    /* função alterar o asset a ser editado */
    changeTrack = selectedOption =>
    {
        window.location = '/products/'+this.state.productId+'/asset/'+selectedOption.value;
    }

    /* função para alterar valor dos inputs referente a música */
    changeValue(key, value, sync)
    {
        var asset = {...this.state.asset}
        asset[key] = value;
        this.setState({asset});

        if(sync === true)
        {
            var event = document.createEvent('Event');
            this.saveAsset(event);
        }
    }

    /* função para alterar valor do select "language" referente a música */
    changeLanguage = selectedOption =>
    {
        this.changeValue('language',selectedOption.value);
        this.setState({'languageSelected':selectedOption.value});
        this.setState({'languageSelectedLabel':selectedOption.label});
    }

    /* função para alterar valor do select "genre" referente a música */
    changeGenre = selectedOption =>
    {
        this.changeValue('genre',selectedOption.value);
        this.setState({'genreSelected':selectedOption.value});
        this.setState({'genreSelectedLabel':selectedOption.label});
    }

    /* função para alterar valor do select "alternate_genre" referente a música */
    changeAlternateGenre = selectedOption =>
    {
        this.changeValue('alternate_genre',selectedOption.value);
        this.setState({'alternateGenreSelected':selectedOption.value});
        this.setState({'alternateGenreSelectedLabel':selectedOption.label});
    }

    /* função para alterar valor do select "recording_year" referente a música */
    changeRecordingYear = selectedOption =>
    {
        this.changeValue('recording_year',selectedOption.value);
        this.setState({'recordingYearSelected':selectedOption.value});
        this.setState({'recordingYearSelectedLabel':selectedOption.label});
    }

    /* função para alterar valor do select "p_line_year" referente a música */
    changePLineYear = selectedOption =>
    {
        this.changeValue('p_line_year',selectedOption.value);
        this.setState({'pLineYearSelected':selectedOption.value});
        this.setState({'pLineYearSelectedLabel':selectedOption.label});
    }

    /* função para alterar valor de um input com datepicker */
    changeDateValue(id, date)
    {
        let month   = date.getMonth() + 1;
        if(parseInt(month) < 10)
        {
            month = "0"+month;
        }
        let day   = date.getDate();
        if(parseInt(day) < 10)
        {
            day = "0"+day;
        }
        let newDate = date.getFullYear() +'-'+ month +'-'+ day;
        this.changeValue(id, newDate);
        newDate = new Date(newDate +' 12:00:00');
        this.setState({'assetReleaseDateFormat':newDate});

    }


    /* função para salvar os dados da música */
    saveAsset = async(e) => {
        e.preventDefault();

        //apenas executa a função caso o álbum não esteja em sincronização
        const {sync} = this.state;

        if(!sync)
        {
            //atualiza o status da sincronização
            this.setState({sync: true});

            //adiciona um delay de 10 segundos para buscar os dados a serem sincronizados
            await sleep(10000)

            const{asset} = this.state;

            try 
            {
                var result = await api.updateAsset(asset, asset.id);

                if(!result.success)
                {
                    toast.error(result.log);

                    //libera a função para ser executada novamente
                    this.setState({sync: false});

                    return null;
                }
                else 
                {
                    toast.success(result.log);

                    //libera a função para ser executada novamente
                    this.setState({sync: false});
                }
            }
            catch(err)
            {
                toast.error(err.message);
            
                //libera a função para ser executada novamente
                this.setState({sync: false});
            }

        }
        
    }

    

    render(){

        return (
            <div className="app-content container" id='main-div'>
                <div className="content-overlay"></div>
                <div className="header-navbar-shadow"></div>
                <div className="content-wrapper">
                    <div className="content-header row">
                    </div>
                    <div className="content-body">
                        <section>
                            <div>
                                <div className="d-flex flex-dir-column align-items-center">
                                    <CardMenu />
                                </div>
                            </div>
                        </section>

                        {this.state.loader === true ? (
                            <Loading type='spin' color='#f3f3f3' />

                        ) : (
                            <div>
                                 <section>
                                    <div className="row row-no-margin">
                                        <div className="col-xl-12">
                                            <div className="card" style={{width: '100%'}}>
                                                <div className="pb-1">
                                                    <div className="row row-no-margin pt-2 px-2">
                                                        <div className="col-8">
                                                            <h5>
                                                                <Link to={"/products/"+this.state.productId}>
                                                                    Voltar para o álbum
                                                                </Link>
                                                                <i className="feather icon-chevron-right"></i>
                                                                {this.state.asset.name}
                                                                
                                                            </h5>
                                                        </div>
                                                        <div className="col-4">
                                                            <div>
                                                                <Select
                                                                    placeholder="Visualizar outra música"
                                                                    id="track" 
                                                                    value={{label : this.state.trackSelectedLabel}}
                                                                    defaultValue={this.state.trackSelected}
                                                                    onChange={this.changeTrack}
                                                                    options={this.state.tracks}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card-content">
                                                    <div className="card-body pt-1">
                                                        <div className="row row-no-margin">
                                                            <div className="col-1">
                                                                <i className="fa fa-music fa-3x"></i>
                                                            </div>
                                                            <div className="col-8">
                                                                <h4>{this.state.asset.name}</h4>
                                                                <h6 className="text-muted">{this.state.asset.isrc}</h6>
                                                            </div>
                                                        </div>

                                                        <div className="row row-no-margin mt-4">
                                                            <ul className="nav nav-pills nav-justified">
                                                                <li className="nav-item product-tab-option">
                                                                    <a className="nav-link active" id="tab-option-info" data-toggle="pill" href="#tab-container-info" aria-expanded="false">
                                                                        Informações da faixa
                                                                    </a>
                                                                </li>
                                                                <li className="nav-item product-tab-option">
                                                                    <a className="nav-link" id="tab-option-artists" data-toggle="pill" href="#tab-container-artists" aria-expanded="true">
                                                                        Artistas
                                                                    </a>
                                                                </li>
                                                                <li className="nav-item product-tab-option">
                                                                    <a className="nav-link" id="tab-option-contributors" data-toggle="pill" href="#tab-container-contributors" aria-expanded="true">
                                                                        Colaboradores
                                                                    </a>
                                                                </li>
                                                                <li className="nav-item product-tab-option">
                                                                    <a className="nav-link" id="tab-option-publishers" data-toggle="pill" href="#tab-container-publishers" aria-expanded="true">
                                                                        Editora
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </div>

                                                        <div className="tab-content">

                                                            {/* Container de informações da música */}
                                                            <AssetMain
                                                                state={this.state} 
                                                                loadProduct={this.loadProduct}
                                                                changeValue={this.changeValue}
                                                                changeLanguage={this.changeLanguage}
                                                                changeGenre={this.changeGenre}
                                                                changeAlternateGenre={this.changeAlternateGenre}
                                                                changeRecordingYear={this.changeRecordingYear}
                                                                changePLineYear={this.changePLineYear}
                                                                changeDateValue={this.changeDateValue}
                                                            />   

                                                            {/* Container de artistas */}
                                                            <AssetArtists
                                                                state={this.state} 
                                                                loadAsset={this.loadAsset}
                                                            />   

                                                            {/* Container de colaboradores */}
                                                            <AssetContributors
                                                                state={this.state} 
                                                                loadAsset={this.loadAsset}
                                                            />   

                                                            {/* Container de editoras */}
                                                            <AssetPublishers
                                                                state={this.state} 
                                                                loadAsset={this.loadAsset}
                                                            />                                             

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </section>
                            </div>
                        )}
                        
                    </div>
                </div>
            </div>  
        
        );
    }
}

export default withRouter(Asset);

