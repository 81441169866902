import React, {Component} from 'react';
import {BrowserRouter, Switch, Route} from 'react-router-dom';
import api from './api';
import './style.css';

//Pages
import Index from './components/pages/Index';
import Login from './components/pages/Login';
import Logout from './components/pages/Logout';
import ResetPassword from './components/pages/ResetPassword';
import Dashboard from './components/pages/Dashboard';
import UpdatePassword from './components/pages/UpdatePassword';
import Product from './components/pages/Product';
import Profile from './components/pages/Profile';
import Asset from './components/pages/Asset';
//Errors
import Error401 from './components/errors/401';
import Error404 from './components/errors/404';
//import Error500 from './components/errors/500';
//Layouts
import HeaderNonAuth from './components/layouts/NonAuth/Header';
import HeaderAuth from './components/layouts/Auth/Header';
import FooterAuth from './components/layouts/Auth/Footer';


class Routes extends Component {

    render(){
        return api.getConnectUuid() == null ? (
            <BrowserRouter>
                <HeaderNonAuth/>
                <Switch>
                    <Route exact path="/" component={Index}></Route>
                    <Route exact path="/login" component={Login}></Route>
                    <Route exact path="/password/reset" component={ResetPassword}></Route>
                    <Route exact path="/dashboard" component={Error401}></Route>
                    <Route path="*" component={Error404}></Route>
                </Switch>
            </BrowserRouter>
        ) : (
            <BrowserRouter>
                <HeaderAuth />
               
                <div id='app-container'>
                    <Switch>
                        <Route exact path="/"   component={Index}></Route>
                        <Route exact path="/login" component={Login}></Route>
                        <Route exact path="/logout" component={Logout}></Route>
                        <Route exact path="/dashboard" component={Dashboard}></Route>
                        <Route exact path="/password/update" component={UpdatePassword}></Route>
                        <Route exact path="/profile" component={Profile}></Route>
                        <Route exact path="/products/:id" component={Product}></Route>
                        <Route exact path="/products/:id/asset/:assetId" component={Asset}></Route>
                        <Route path="*" component={Error404}></Route>
                    </Switch>
                </div>
                <FooterAuth/>
            </BrowserRouter>
        );
            
    }
}

export default Routes;