import api from "../api";

class Publisher {

    //URL base para requisições na API
    baseUrl = process.env.REACT_APP_API_URL


    //lista de editoras
    getPublishers = async() => {
        let success      = true;
        let log          = '';
        let publishers   = [];
        let url          = this.baseUrl+`/publishers`;

        await fetch(url, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+api.getConnectUuid()
            },          
            method: 'GET',
        })
        .then((r) => r.json())
        .then((json) => {
 
            success = json.success;
            log = json.log;
            publishers = json.publishers

        }).catch(error => {

            success = false;
            log = 'Internal Server Error';

        });

        return {
            success: success,
            log: log,
            publishers: publishers
        };
    }


    //cadastro de editora
    createPublisher = async(name, publishingHouse, assetId) => {
        let success     = true;
        let log         = '';
        let publisher   = '';
        let url         = this.baseUrl+`/publishers`;

        await fetch(url, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+api.getConnectUuid()
            },          
            method: 'POST',
            body: JSON.stringify({name: name, publishing_house: publishingHouse, asset_id: assetId})
        })
        .then((r) => r.json())
        .then((json) => {
 
            success = json.success;
            log = json.log;
            publisher = json.publisher

        }).catch(error => {

            success = false;
            log = 'Internal Server Error';

        });

        return {
            success: success,
            log: log,
            publisher: publisher
        };
    }

    //lista de "publishing houses"
    getPublishingHouses = async() => {
        let success      = true;
        let log          = '';
        let houses       = [];
        let url          = this.baseUrl+`/publishers/houses`;

        await fetch(url, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+api.getConnectUuid()
            },          
            method: 'GET',
        })
        .then((r) => r.json())
        .then((json) => {
 
            success = json.success;
            log = json.log;
            houses = json.houses

        }).catch(error => {

            success = false;
            log = 'Internal Server Error';

        });

        return {
            success: success,
            log: log,
            houses: houses
        };
    }


    //exclusão de editora da música
    deletePublisherFromAsset = async(publisherId, assetId) => {
        let success     = true;
        let log         = '';
        let url         = this.baseUrl+`/publishers/`+publisherId+`/asset/`+assetId;

        await fetch(url, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+api.getConnectUuid()
            },          
            method: 'DELETE',
            body: JSON.stringify({})
        })
        .then((r) => r.json())
        .then((json) => {
 
            success = json.success;
            log = json.log;

        }).catch(error => {

            success = false;
            log = 'Internal Server Error';

        });

        return {
            success: success,
            log: log,
        };
    }
}

export default new Publisher();