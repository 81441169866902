import api from "../api";

class Product {

    //URL base para requisições na API
    baseUrl = process.env.REACT_APP_API_URL


    //lista e busca de álbuns
    getProducts = async() => {
        let success  = true;
        let log      = '';
        let products = [];
        let url      = this.baseUrl+`/products`;

        await fetch(url, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+api.getConnectUuid()
            },          
            method: 'GET',
        })
        .then((r) => r.json())
        .then((json) => {
 
            success = json.success;
            log = json.log;
            products = json.products

        }).catch(error => {

            success = false;
            log = 'Internal Server Error';

        });

        return {
            success: success,
            log: log,
            products: products
        };
    }


    //cadastro de álbum
    create = async(titulo) => {
        let success  = true;
        let log      = '';
        let product  = null;
        let url      = this.baseUrl+`/products`;

        await fetch(url, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+api.getConnectUuid()
            },          
            method: 'POST',
            body: JSON.stringify({name: titulo})
        })
        .then((r) => r.json())
        .then((json) => {
      
            success = json.success;
            log = json.log;
            product = json.product; 
            
        }).catch(error => {

            success = false;
            log = 'Internal Server Error';

        });

        return {
            success: success,
            log: log,
            product: product
        };
    }


    //busca de álbum
    getProduct = async(productId) => {
        let success  = true;
        let log      = '';
        let product  = '';
        let url      = this.baseUrl+`/products/`+productId;

        await fetch(url, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+api.getConnectUuid()
            },          
            method: 'GET',
        })
        .then((r) => r.json())
        .then((json) => {
 
            success = json.success;
            log = json.log;
            product = json.product

        }).catch(error => {

            success = false;
            log = 'Internal Server Error';

        });

        return {
            success: success,
            log: log,
            product: product
        };
    }


    //edição de álbum
    updateProduct = async(product, productId) => {
        let success  = true;
        let log      = '';
        let url      = this.baseUrl+`/products/`+productId;

        await fetch(url, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+api.getConnectUuid()
            },          
            method: 'PUT',
            body: JSON.stringify(product)
        })
        .then((r) => r.json())
        .then((json) => {
 
            success = json.success;
            log = json.log;
            product = json.product

        }).catch(error => {

            success = false;
            log = 'Internal Server Error';

        });

        return {
            success: success,
            log: log,
            product: product
        };
    }


    //reordena músicas do álbum
    sortAssets = async(productId, assets) => {
        let success  = true;
        let log      = '';
        let url      = this.baseUrl+`/products/`+productId+`/assets/sort`;

        let data = {
            assets: assets
        }

        await fetch(url, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+api.getConnectUuid()
            },     
            method: 'POST',
            body: JSON.stringify(data)
        })
        .then((r) => r.json())
        .then((json) => {
            success = json.success;
            log = json.log;

        }).catch(error => {
            success = false;
            log = 'Internal Server Error';
        });

        return {
            success: success,
            log: log,
        };
    }


    //atualiza foto de capa do álbum
    updateProductCoverImage = async(productId, imageFile) => {
        let success  = true;
        let log      = '';
        let image    = '';
        let url      = this.baseUrl+`/products/`+productId+`/image`;

        const formData = new FormData()
        formData.append('image', imageFile)

        await fetch(url, {
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer '+api.getConnectUuid()
            },     
            method: 'POST',
            body: formData
        })
        .then((r) => r.json())
        .then((json) => {
            success = json.success;
            log = json.log;
            image = json.image

        }).catch(error => {
            success = false;
            log = 'Internal Server Error';
        });

        return {
            success: success,
            log: log,
            image: image
        };
    }


    //exclusão do álbum
    deleteProduct = async(productId) => {
        let success  = true;
        let log      = '';
        let url      = this.baseUrl+`/products/`+productId;

        await fetch(url, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+api.getConnectUuid()
            },     
            method: 'DELETE',
            body: JSON.stringify({})
        })
        .then((r) => r.json())
        .then((json) => {
            success = json.success;
            log = json.log;

        }).catch(error => {
            success = false;
            log = 'Internal Server Error';
        });

        return {
            success: success,
            log: log,
        };
    }


    //submissão do álbum
    submitProduct = async(productId) => {
        let success  = true;
        let log      = '';
        let url      = this.baseUrl+`/products/`+productId+`/submit`;

        await fetch(url, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+api.getConnectUuid()
            },     
            method: 'POST',
            body: JSON.stringify({})
        })
        .then((r) => r.json())
        .then((json) => {
            success = json.success;
            log = json.log;

        }).catch(error => {
            success = false;
            log = 'Internal Server Error';
        });

        return {
            success: success,
            log: log,
        };
    }


    //publicação do álbum
    publishProduct = async(productId) => {
        let success  = true;
        let log      = '';
        let url      = this.baseUrl+`/products/`+productId+`/publish`;

        await fetch(url, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+api.getConnectUuid()
            },     
            method: 'POST',
            body: JSON.stringify({})
        })
        .then((r) => r.json())
        .then((json) => {
            success = json.success;
            log = json.log;

        }).catch(error => {
            success = false;
            log = 'Internal Server Error';
        });

        return {
            success: success,
            log: log,
        };
    }

    //clone do álbum
    cloneProduct = async(productId) => {
        let success  = true;
        let log      = '';
        let product  = '';
        let url      = this.baseUrl+`/products/`+productId+`/clone`;

        await fetch(url, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+api.getConnectUuid()
            },     
            method: 'POST',
            body: JSON.stringify({})
        })
        .then((r) => r.json())
        .then((json) => {
            success = json.success;
            log = json.log;
            product = json.product;

        }).catch(error => {
            success = false;
            log = 'Internal Server Error';
        });

        return {
            success: success,
            log: log,
            product: product
        };
    }


    //takedown do álbum
    takedownProduct = async(productId, message) => {
        let success  = true;
        let log      = '';
        let url      = this.baseUrl+`/products/`+productId+`/takedown`;

        await fetch(url, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+api.getConnectUuid()
            },     
            method: 'POST',
            body: JSON.stringify({message: message})
        })
        .then((r) => r.json())
        .then((json) => {
            success = json.success;
            log = json.log;

        }).catch(error => {
            success = false;
            log = 'Internal Server Error';
        });

        return {
            success: success,
            log: log,
        };
    }
}

export default new Product();