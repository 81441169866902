import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import Modal from '../../plugins/Modal';
import { toast } from 'react-toastify';
import api from '../../../api';

class Cover extends Component {

    constructor(props){
        super(props);
        this.state = {
            modalDelete: false,
            modalClone: false,
            modalSubmit: false,
            modalPublish: false,
            modalTakedown: false,
            apiUrl: process.env.REACT_APP_API_URL,
            btnDeleteDisabled: false,
            btnCloneDisabled: false,
            btnSubmitDisabled: false,
            btnPublishDisabled: false,
            btnTakedownDisabled: false,
            takedownMessage: ''
        };

        this.openModal       = this.openModal.bind(this);
        this.closeModal      = this.closeModal.bind(this);
        this.deleteProduct   = this.deleteProduct.bind(this);
        this.cloneProduct    = this.cloneProduct.bind(this);
        this.submitProduct   = this.submitProduct.bind(this);
        this.publishProduct  = this.publishProduct.bind(this);
        this.takedownProduct = this.takedownProduct.bind(this);
    }

    /* abre a modal de acordo com o tipo da modal */
    openModal(type){
        if(type === 'delete')
        {
            this.setState({modalDelete: true});
        }
        else if(type === 'clone')
        {
            this.setState({modalClone: true});
        }
        else if(type === 'submit')
        {
            this.setState({modalSubmit: true});
        }
        else if(type === 'publish')
        {
            this.setState({modalPublish: true});
        }
        else if(type === 'takedown')
        {
            this.setState({modalTakedown: true});
        }
    }


    /* fecha a modal de acordo com o tipo da modal */
    closeModal(type){
        if(type === 'delete')
        {
            this.setState({modalDelete: false});
        }
        else if(type === 'clone')
        {
            this.setState({modalClone: false});
        }
        else if(type === 'submit')
        {
            this.setState({modalSubmit: false});
        }
        else if(type === 'publish')
        {
            this.setState({modalPublish: false});
        }
        else if(type === 'takedown')
        {
            this.setState({modalTakedown: false});
        }
    }

    /* exclui o álbum */
    deleteProduct = async(e) => {

        e.preventDefault();
        this.setState({btnDeleteDisabled:true});

        try 
        {
            var result = await api.deleteProduct(this.props.state.product.id);

            if(!result.success)
            {
                toast.error(result.log);

                this.setState({btnDeleteDisabled:false});

                return null;
            }
            else 
            {
                return this.props.history.replace('/dashboard');
            }
        }
        catch(err)
        {
            toast.error(err.message);

            this.setState({btnDeleteDisabled:false});
        }
    }

    /* submete o álbum */
    submitProduct = async(e) => {

        e.preventDefault();

        this.setState({btnSubmitDisabled:true});

        try 
        {
            var result = await api.submitProduct(this.props.state.product.id);

            if(!result.success)
            {
                toast.error(result.log);

                this.setState({btnSubmitDisabled:false});

                return null;
            }
            else 
            {
                this.props.loadProduct();

                this.setState({btnSubmitDisabled:false});
            }
        }
        catch(err)
        {
            toast.error(err.message);

            this.setState({btnSubmitDisabled:false});
        }
    }


    /* publica o álbum */
    publishProduct = async(e) => {

        e.preventDefault();

        this.setState({btnPublishDisabled:true});

        try 
        {
            var result = await api.publishProduct(this.props.state.product.id);

            if(!result.success)
            {
                toast.error(result.log);

                this.setState({btnPublishDisabled:false});

                return null;
            }
            else 
            {
                this.props.loadProduct();

                this.setState({btnPublishDisabled:false});
            }
        }
        catch(err)
        {
            toast.error(err.message);

            this.setState({btnPublishDisabled:false});
        }
    }

    /* clona o álbum */
    cloneProduct = async(e) => {

        e.preventDefault();

        this.setState({btnCloneDisabled:true});

        try 
        {
            var result = await api.cloneProduct(this.props.state.product.id);

            if(!result.success)
            {
                toast.error(result.log);

                this.setState({btnCloneDisabled:false});

                return null;
            }
            else 
            {
                toast.success(result.log);

                this.setState({btnCloneDisabled:false});

                window.location = '/products/'+result.product.id;
            }
        }
        catch(err)
        {
            toast.error(err.message);

            this.setState({btnCloneDisabled:false});
        }
    }


    /* takedown do álbum */
    takedownProduct = async(e) => {

        e.preventDefault();

        this.setState({btnTakedownDisabled:true});

        try 
        {
            var result = await api.takedownProduct(this.props.state.product.id);

            if(!result.success)
            {
                toast.error(result.log);

                this.setState({btnTakedownDisabled:false});

                return null;
            }
            else 
            {
                this.props.loadProduct();

                this.setState({btnTakedownDisabled:false});

                this.closeModal('takedown');
            }
        }
        catch(err)
        {
            toast.error(err.message);

            this.setState({btnTakedownDisabled:false});
        }
    }


    render() {
        return (
            
            <div className="col-md-3">

                <div className="card">
                    <div className="card-content">

                        <input type="file" 
                            ref={input => this.coverImageInput = input}
                            style={{display: 'none'}} 
                            onChange={(e) =>{ this.props.changeCoverImage(e)}} />

                        <div id="product-cover-image-container">

                            <img className="card-img-top img-fluid" src={this.props.state.coverImage !== '' ? this.props.state.apiUrl+this.props.state.coverImage : "/app-assets/images/icons/disco.png"} alt=""/>

                            {this.props.state.coverImageUploading === true ? (
                                <div id="product-cover-image-trigger">
                                    Processando...
                                </div>
                            ) : (
                                <div id="product-cover-image-trigger" onClick={() => this.coverImageInput.click()}>
                                    Alterar foto de capa
                                </div>
                            )}
                        </div>
                    
                        
                        <div className="card-body">
                            <h3>{this.props.state.product.name}</h3>

                            <p className="card-text">
                                UPC/EAN: {this.props.state.product.upc}
                            </p>


                            {this.props.state.product.state === 'PENDING' && this.props.state.product.suborg_state !== 'REJECTED' ? (
                                <div className="badge badge-warning font-weight-700 mr-1 mb-1">
                                    <i className="feather icon-edit"></i>
                                    <span className="pl-1">Rascunho</span>
                                </div>
                            ) : (
                                <div></div>
                            )} 

                            {this.props.state.product.state === 'PENDING' && this.props.state.product.suborg_state === 'REJECTED'  ? (
                                <div>
                                    <div className="badge badge-danger font-weight-700 mr-1 mb-1">
                                        <i className="feather icon-edit"></i>
                                        <span className="pl-1">Rejeitado</span>
                                    </div><br />
                                    <strong>Rejeição: {this.props.state.product.last_review_item.reject_message}</strong>
                                </div>
                            ) : (
                                <div></div>
                            )} 

                            {this.props.state.product.state === 'SUBMITTED' ? (
                                <div className="badge badge-info font-weight-700 mr-1 mb-1">
                                    <i className="feather icon-edit"></i>
                                    <span className="pl-1">Em análise</span>
                                </div>
                            ) : (
                                <div></div>
                            )} 

                            {this.props.state.product.state === 'ACCEPTED' ? (
                                <div className="badge badge-success font-weight-700 mr-1 mb-1">
                                    <i className="feather icon-edit"></i>
                                    <span className="pl-1">Aprovado</span>
                                </div>
                            ) : (
                                <div></div>
                            )} 

                            {this.props.state.product.state === 'PUBLISHED' ? (
                                <div className="badge badge-success font-weight-700 mr-1 mb-1">
                                <i className="feather icon-check"></i>
                                <span className="pl-1">Publicado</span>
                            </div>
                            ) : (
                                <div></div>
                            )} 
                        

                            <br />
                            <div className="row mt-2">
                                <div className="col-6">
                                    <button onClick={(e) => {this.openModal('clone')}} className="btn btn-sm btn-block btn-outline-info text-center">
                                        Copiar
                                    </button>
                                </div>
                                <div className="col-6">

                                    {this.props.state.product.state === 'PUBLISHED' ? (
                                        <button onClick={(e) => {this.openModal('takedown')}} className="btn btn-sm btn-block btn-primary text-center">
                                            Tirar do ar
                                        </button>
                                    ) :  (<div></div>)}


                                    {this.props.state.product.state === 'PENDING' ? (
                                        <button onClick={(e) => {this.openModal('submit')}} className="btn btn-sm btn-block btn-primary text-center">
                                            Enviar
                                        </button>
                                    ) :  (<div></div>)}


                                    {this.props.state.product.state === 'ACCEPTED' ? (
                                        <button onClick={(e) => {this.openModal('publish')}} className="btn btn-sm btn-block btn-primary text-center">
                                            Publicar
                                        </button>
                                    ) :  (<div></div>)}



                                </div>
                            </div>
                            <hr />
                            <div className="mt-2 col-12 row-no-padding">
                                <button onClick={(e) => {this.openModal('delete')}} className="btn btn-sm btn-block btn-outline-primary">Excluir</button>
                            </div>
                        </div>
                    </div>
                </div>


                <Modal
                    class="modal-wrapper-sm"
                    show={this.state.modalDelete}
                    close={(e) => {this.closeModal('delete')}}
                    disableClose={this.state.btnDeleteDisabled}
                    title='Excluir o álbum'>
                        <div className="modal-body">
                            <h6>Deseja realmente excluir o álbum?</h6>
                            <label>Não será possível reverter essa ação.</label>
                        </div>
                        <div className="modal-footer">
                            <button type="button" disabled={this.state.btnDeleteDisabled} className="btn btn-default" onClick={(e) => {this.closeModal('delete')}}>
                                {this.state.btnDeleteDisabled ? ('') : ('Voltar')}
                            </button>
                            <button type="button" disabled={this.state.btnDeleteDisabled} className="btn btn-primary" onClick={(e) => {this.deleteProduct(e)}}>
                                {this.state.btnDeleteDisabled ? ('Processando...') : ('Confirmar')}
                            </button>
                        </div>
                </Modal>

                <Modal
                    class="modal-wrapper-sm"
                    show={this.state.modalClone}
                    close={(e) => {this.closeModal('clone')}}
                    disableClose={this.state.btnCloneDisabled}
                    title='Gerar uma cópia do álbum'>
                        <div className="modal-body">
                            <h6>Deseja realmente gerar uma cópia?</h6>
                        </div>
                        <div className="modal-footer">
                            <button type="button" disabled={this.state.btnCloneDisabled} className="btn btn-default" onClick={(e) => {this.closeModal('clone')}}>
                                {this.state.btnCloneDisabled ? ('') : ('Voltar')}
                            </button>
                            <button type="button" disabled={this.state.btnCloneDisabled} className="btn btn-primary" onClick={(e) => {this.cloneProduct(e)}}>
                                {this.state.btnCloneDisabled ? ('Processando...') : ('Confirmar')}
                            </button>
                        </div>
                </Modal>


                <Modal
                    class="modal-wrapper-sm"
                    show={this.state.modalSubmit}
                    close={(e) => {this.closeModal('submit')}}
                    disableClose={this.state.btnSubmitDisabled}
                    title='Enviar álbum para aprovação'>
                        <div className="modal-body">
                            <h6>Deseja realmente enviar para aprovação?</h6>
                        </div>
                        <div className="modal-footer">
                            <button type="button" disabled={this.state.btnSubmitDisabled} className="btn btn-default" onClick={(e) => {this.closeModal('submit')}}>
                                {this.state.btnSubmitDisabled ? ('') : ('Voltar')}
                            </button>
                            <button type="button" disabled={this.state.btnSubmitDisabled} className="btn btn-primary" onClick={(e) => {this.submitProduct(e)}}>
                                {this.state.btnSubmitDisabled ? ('Processando...') : ('Confirmar')}
                            </button>
                        </div>
                </Modal>


                <Modal
                    class="modal-wrapper-sm"
                    show={this.state.modalPublish}
                    close={(e) => {this.closeModal('publish')}}
                    disableClose={this.state.btnPublishDisabled}
                    title='Publicar álbum'>
                        <div className="modal-body">
                            <h6>Deseja realmente disponibilizar o álbum nas plataformas?</h6>
                        </div>
                        <div className="modal-footer">
                            <button type="button" disabled={this.state.btnPublishDisabled} className="btn btn-default" onClick={(e) => {this.closeModal('publish')}}>
                                {this.state.btnPublishDisabled ? ('') : ('Voltar')}
                            </button>
                            <button type="button" disabled={this.state.btnPublishDisabled} className="btn btn-primary" onClick={(e) => {this.publishProduct(e)}}>
                                {this.state.btnPublishDisabled ? ('Processando...') : ('Confirmar')}
                            </button>
                        </div>
                </Modal>


                <Modal
                    class="modal-wrapper-sm"
                    show={this.state.modalTakedown}
                    close={(e) => {this.closeModal('takedown')}}
                    disableClose={this.state.btnTakedownDisabled}
                    title='Retirar álbum das plataformas'>
                        <div className="modal-body">
                            <div>
                                <label>Justificativa para retirada das plataformas</label>
                                <input type="text" className="form-control" 
                                    value={this.state.takedownMessage} 
                                    onChange={(e) => {this.setState({'takedownMessage':e.target.value})}}/>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" disabled={this.state.btnTakedownDisabled} className="btn btn-default" onClick={(e) => {this.closeModal('takedown')}}>
                                {this.state.btnTakedownDisabled ? ('') : ('Voltar')}
                            </button>
                            <button type="button" disabled={this.state.btnTakedownDisabled} className="btn btn-primary" onClick={(e) => {this.takedownProduct(e)}}>
                                {this.state.btnTakedownDisabled ? ('Processando...') : ('Confirmar')}
                            </button>
                        </div>
                </Modal>
            </div>
        
        )
    }
}

export default withRouter(Cover);
