import api from "../api";

class Profile {

    //URL base para requisições na API
    baseUrl = process.env.REACT_APP_API_URL


    //busca os dados do usuário logado
    getProfile = async() => {
        let success  = true;
        let log      = '';
        let profile  = '';
        let url      = this.baseUrl+`/profile`;

        await fetch(url, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+api.getConnectUuid()
            },          
            method: 'GET',
        })
        .then((r) => r.json())
        .then((json) => {
 
            success = json.success;
            log = json.log;
            profile = json.profile

        }).catch(error => {

            success = false;
            log = 'Internal Server Error';

        });

        return {
            success: success,
            log: log,
            profile: profile
        };
    }


    //atualiza perfil do usuário logado
    updateProfile = async(data) => {
        let success  = true;
        let log      = '';
        let profile  = '';
        let url      = this.baseUrl+`/profile`;

        await fetch(url, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+api.getConnectUuid()
            },     
            method: 'PUT',
            body: JSON.stringify(data)
        })
        .then((r) => r.json())
        .then((json) => {
            success = json.success;
            log = json.log;
            profile = json.profile

        }).catch(error => {
            success = false;
            log = 'Internal Server Error';
        });

        return {
            success: success,
            log: log,
            profile: profile
        };
    }

    //atualiza foto perfil do usuário logado
    updatePhoto = async(imageFile) => {
        let success  = true;
        let log      = '';
        let profile  = '';
        let url      = this.baseUrl+`/profile/photo`;

        const formData = new FormData()
        formData.append('photo', imageFile)

        await fetch(url, {
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer '+api.getConnectUuid()
            },     
            method: 'POST',
            body: formData
        })
        .then((r) => r.json())
        .then((json) => {
            success = json.success;
            log = json.log;
            profile = json.profile

        }).catch(error => {
            success = false;
            log = 'Internal Server Error';
        });

        return {
            success: success,
            log: log,
            profile: profile
        };
    }

}

export default new Profile();