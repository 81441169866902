import api from "../api";

class Asset {

    //URL base para requisições na API
    baseUrl = process.env.REACT_APP_API_URL


    //retorna músicas cadastradas
    getAssets = async() => {
        let success  = true;
        let log      = '';
        let url      = this.baseUrl+`/assets/all`;
        let assets   = "";

        await fetch(url, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+api.getConnectUuid()
            },          
            method: 'GET'
        })
        .then((r) => r.json())
        .then((json) => {
 
            success = json.success;
            log = json.log;
            assets = json.assets

        }).catch(error => {

            success = false;
            log = 'Internal Server Error';

        });

        return {
            success: success,
            log: log,
            assets: assets
        };
    }

    
    //cadastra asset e vincula ao produto caso sejam informado
    createAsset = async(name, isrc, file, productId) => {
        let success  = true;
        let log      = '';
        let url      = this.baseUrl+`/assets/upload`;

        var formData = new FormData();
        formData.append('name', name);
        formData.append('isrc', isrc);
        formData.append('file', file);
        formData.append('product_id', productId);

        await fetch(url, {
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer '+api.getConnectUuid()
            },     
            method: 'POST',
            body: formData
        })
        .then((r) => r.json())
        .then((json) => {
 
            success = json.success;
            log = json.log;

        }).catch(error => {

            success = false;
            log = 'Internal Server Error';

        });

        return {
            success: success,
            log: log
        };
    }

    //adiciona música ao álbum informado
    addAssetToProduct = async(assetId, productId) => {
        let success  = true;
        let log      = '';
        let url      = this.baseUrl+`/assets/`+assetId+`/product/`+productId;

        await fetch(url, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+api.getConnectUuid()
            },          
            method: 'POST'
        })
        .then((r) => r.json())
        .then((json) => {
 
            success = json.success;
            log = json.log;

        }).catch(error => {

            success = false;
            log = 'Internal Server Error';

        });

        return {
            success: success,
            log: log
        };
    }

    //exclui música do álbum informado
    deleteAssetFromProduct = async(assetId, productId) => {
        let success  = true;
        let log      = '';
        let url      = this.baseUrl+`/assets/`+assetId+`/product/`+productId;

        await fetch(url, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+api.getConnectUuid()
            },          
            method: 'DELETE'
        })
        .then((r) => r.json())
        .then((json) => {
 
            success = json.success;
            log = json.log;

        }).catch(error => {

            success = false;
            log = 'Internal Server Error';

        });

        return {
            success: success,
            log: log
        };
    }


    //retorna música específica
    getAsset = async(assetId) => {
        let success  = true;
        let log      = '';
        let url      = this.baseUrl+`/assets/`+assetId;
        let asset    = "";

        await fetch(url, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+api.getConnectUuid()
            },          
            method: 'GET'
        })
        .then((r) => r.json())
        .then((json) => {
 
            success = json.success;
            log = json.log;
            asset = json.asset

        }).catch(error => {

            success = false;
            log = 'Internal Server Error';

        });

        return {
            success: success,
            log: log,
            asset: asset
        };
    }


    //edição de música
    updateAsset = async(asset, assetId) => {
        let success  = true;
        let log      = '';
        let url      = this.baseUrl+`/assets/`+assetId;

        await fetch(url, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+api.getConnectUuid()
            },          
            method: 'PUT',
            body: JSON.stringify(asset)
        })
        .then((r) => r.json())
        .then((json) => {
 
            success = json.success;
            log = json.log;
            asset = json.asset

        }).catch(error => {

            success = false;
            log = 'Internal Server Error';

        });

        return {
            success: success,
            log: log,
            asset: asset
        };
    }


}

export default new Asset();