import React, {Component} from 'react';
import Routes from './routes';
import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class App extends Component {

    state = {
        loader: false,
    };

    componentDidMount(){
        this.setState({loader: true})
    }

    render(){

        return this.state.loader !== false ? (
            <div>
                <Routes/>

                <ToastContainer style={{zIndex: '9999999'}} />
            </div>
        ) : (
            <h1>Carregando...</h1>
        );
    }
}

export default App;
