import React from 'react';
import './style.css';

const Modal = (props) => {
    return (

        <div className={props.show ? 'back-drop' : 'back-drop-closed'}>
            <div className={props.class ? props.class : 'modal-wrapper modal-wrapper-lg'}
                style={{
                    transform: props.show ? 'translateY(0vh)' : 'translateY(-100vh)',
                    opacity: props.show ? '1' : '0'
                }}>
                <div className="modal-header">
                    <h3>{props.title}</h3>
                    {props.disableClose ? ('') : (
                        <span className="close-modal-btn" onClick={props.close} disabled={props.disableClose}>×</span>
                    )}
                </div>
                <div className="modal-body">
                    {props.children}
                </div>
            </div>
        </div>
    )
}

export default Modal;