import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import api from '../../../api';
import { toast } from 'react-toastify';

class ResetPassword extends Component{

    constructor(props){
        super(props);
        this.state = {
            email: '',
            password: '',
            btnSubmitDisabled: false,
        };

        this.reset = this.reset.bind(this);
    }

    componentDidMount()
    {
        if(api.getConnectUuid() !== null)
        {
            this.props.history.replace('/dashboard');
        }
    }

    reset = async(e) => {

        e.preventDefault();
        const {email} = this.state;
        this.setState({btnSubmitDisabled:true});

        try 
        {
            var result = await api.resetPassword(email);

            if(!result.success)
            {
                toast.error(result.log);
            }
            else 
            {
                toast.success(result.log);
                this.props.history.replace('/login');
            }
        }
        catch(err)
        {
            toast.error(err.message);
        }

        this.setState({btnSubmitDisabled:false});
        return null;

    }

    render(){
        return(
            <section className="row flexbox-container">
                <div className="col-xl-8 col-11 d-flex justify-content-center">
                    <div className="card bg-authentication rounded-0 mb-0">
                        <div className="row m-0">
                        <div className="col-lg-6 d-lg-block d-none text-center align-self-center px-1 py-0" 
                                        style={{ background: "url(/app-assets/images/custom/login_image.png) no-repeat center center", backgroundSize: "cover"}}>
                                        <div style={{height: "500px", width: "500px"}}></div>
                                    </div>
                        <div className="col-lg-6 col-12 p-0">
                            <div className="card rounded-0 mb-0 px-2">
                            <div className="row justify-content-center">
                                <div className="col-md-6">
                                    <div className="text-center">
                                        <img src="/app-assets/images/custom/logo.png" className="py-2" style={{width: "50px"}} alt="Nikita" />
                                    </div>
                                </div>
                            </div>
                            <p className="px-2 text-center">Insira seu e-mail para receber uma nova senha</p>
                            <div className="card-content">
                                <div className="card-body pt-1">
                                    <form onSubmit={this.reset} method='POST'>
                                        <fieldset className="form-label-group form-group position-relative has-icon-left">
                                            <input type="email" className="form-control" placeholder="E-mail de acesso" required
                                                    name='email' onChange={(e) => {this.setState({email: e.target.value})}} />
                                            <div className="form-control-position">
                                                <i className="feather icon-mail" />
                                            </div>
                                        </fieldset>
                                        
                                        <button type="submit" disabled={this.state.btnSubmitDisabled} className="btn btn-primary btn-block float-center btn-inline">
                                            {this.state.btnSubmitDisabled ? 'Processando...' : 'Gerar nova senha'}
                                        </button>
                                        <div className="text-center" id='link-recuperacao-senha'>
                                            <Link to="/login" className="">Voltar para login</Link>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="login-footer">
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

 export default withRouter(ResetPassword);