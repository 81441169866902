import React from 'react';

function Footer(){
    return(
      <footer id='footer' className="footer position-bottom footer-light navbar-shadow" style={{position: "absolute !important"}}>
        <p className="clearfix blue-grey lighten-2 mb-0">
            <span className="float-md-left d-block d-md-inline-block mt-25">
            Desenvolvido por<a className="text-bold-800 grey darken-2" href="https://2mind.com.br" target="_blank" rel="noopener noreferrer">2Mind Web e Mobile</a>
            </span>
          <button className="btn btn-primary btn-icon scroll-top" type="button"><i className="feather icon-arrow-up" /></button>
        </p>
    </footer>
    );
}

export default Footer;